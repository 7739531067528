// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { Route, Switch, Routes } from "react-router";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      {/* <Routes>
        <Route path="/#/terminos" render={() => {window.location.href="terminos.html"}} />

      </Routes> */}
    </ThemeProvider>
  );
}
