import React from 'react'
// import { Helmet } from "react-helmet";

export function AddLibrary(urlOfTheLibrary) {
      const script = document.createElement('script');
      script.src = urlOfTheLibrary;
      script.async = true;
      document.body.appendChild(script);
    }
const Prueba = ({ smile }) => {
    console.log("smileeee", smile)
    return (
        <div style={{border:"2px solid black" ,width:"300px", height:"300px"}}>
            <div id="JME" code="JME.class" name="JME" archive="JME.jar" width="500px" height="500px">
                <param name="options" value="depict,useopenchemlib" />
                <param name="smiles" value={`${smile}`} />
            </div>
            {/* <Helmet>
          <script src={libreria}
            type="text/javascript" />
        </Helmet> */}
        </div>
    )
}

export default Prueba