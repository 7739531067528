import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './slices/counterSlice'
import languageReducer from './slices/languageReducer'
import userReducer from './slices/userReducer'

export default configureStore({
  reducer: {
    user: userReducer,
   lang:languageReducer,
   counter:counterSlice
  }
})