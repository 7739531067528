import React, { useEffect, useState } from 'react'
import { Container, Grid, Paper, Tab, Tabs, Box, withStyles, TextField, Button, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import { ReactComponent as Logo } from '../assets/logo-title.svg'
import "./css/portada.css"
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { Jsme } from 'jsme-react'
import IMGExample from "../assets/jsme-example.png"
import IMGExample2 from "../assets/jsme-example2.png"
import IMGExample2eng from "../assets/jsme-example2-eng.png"
import { language } from 'src/language';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';


const Portada = ({ searchText, optionSelect, buscar }) => {
  const lang = useSelector((state) => state.lang.value)
  const [value, setValue] = React.useState(0);
  const [smile, setSmile] = useState("")
  const [texto, setTexto] = useState("")
  const logSmiles = (smiles) => {
    // console.log(smiles)
    setSmile(smiles)
    setTexto(smiles)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // console.log(value)
    optionSelect(value);
  }, [value])

  const useStyles = styled({
    tabs: {
      "& .MuiTabs-indicator": {
        display: "none",
        backgroundColor: null
      },
      "&.MuiButtonBase-root.MuiTab-root": {
        backgroundColor: "green"
      }
    }
  })

  const classes = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const sendTextClick = (e) => {
    e.preventDefault();
    // console.log("textof ", texto)
    // console.log("value ", value)
    searchText(texto)
    buscar(e)
  }

  const MostrarBox = () => {
    switch (value) {
      case 0:
        return (
          <div hidden className="portada-container-search-box">
            <Grid
              className="portada-box-search"
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <OutlinedInput
                placeholder="hydroxybenzoic, etc..."
                name="text"
                value={texto}
                onChange={e => setTexto(e.target.value)}
                className="portada-box-input"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Button onClick={(e) => sendTextClick(e)}>{language[lang].btnSearch}</Button>
            </Grid>
          </div>
        )
      case 1:
        return (
          <div hidden className="portada-container-search-box">
            <Grid
              className="portada-box-search"
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <OutlinedInput
                placeholder="hydroxybenzoic, etc..."
                className="portada-box-input"
                name="text"
                value={texto}
                onChange={e => setTexto(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Button onClick={(e) => sendTextClick(e)}>{language[lang].btnSearch}</Button>
            </Grid>
          </div>
        )
      case 2:
        return (
          <Container>
            <div hidden className="portada-jsme-container-search-box">
              <div className="portada-jsme"

              >
                <Grid sx={{ display: { xs: 'none', md: 'block' } }} container justifyContent="center" alignItems="center" textAlign="center" className="portada-jsme-example "
                >
                  <Grid item >
                    <Typography className="portada-jsme-example-text">
                      {language[lang].imageText1}
                    </Typography>
                  </Grid>
                  <Grid item style={{
                    "justify-content": "center",
                    display: "flex"
                  }}>
                    <img src={IMGExample} alt="example" />
                  </Grid>
                  <Grid item>
                    <Typography className="portada-jsme-example-text">
                      {language[lang].imageText2}
                    </Typography>
                  </Grid>
                  <Grid item style={{
                    "justify-content": "center",
                    display: "flex"
                  }}>
                  {
                    lang =="spa"?(
                      <img src={IMGExample2} alt="example" />
                    ):(
                      <img src={IMGExample2eng} alt="example" />
                    )
                  }
                  </Grid>

                </Grid>
                <Grid container justifyContent="center" alignItems="center" textAlign="center" className="portada-jsme-container ">


                  <Jsme
                    className= "jsme-library"
                    width={matches ? "500px" : "350px"}
                    height="418px"
                    options="oldlook,star"
                    onChange={logSmiles}
                  //   height: PropTypes.string.isRequired,
                  // width: PropTypes.string.isRequired,
                  // smiles: PropTypes.string,
                  // options: PropTypes.string,
                  // onChange: PropTypes.func,
                  // src: PropTypes.string 
                  />
                </Grid>
                {/* <Jsme
                width="200px"
                height="200px"
                options="depict,stop"
                smiles="CCO=O"
              /> */}


                <Grid
                  container
                  justifyContent={matches ? "flex-end" : "center"}
                  direction="row"
                  alignItems={matches ? "flex-end" : "center"}
                  className="portada-jsme-container-button"
                >
                  <Button onClick={(e) => sendTextClick(e)} className="portada-jsme-button">{language[lang].btnSearch}</Button>
                </Grid>
              </div>
            </div>
          </Container>
        )
      default:
        return null
    }
  }

  const myStyle = (option) => {
    if (option == value)
      return "portada-box-item-active"
    else
      return "portada-box-item-inactive"
  }
  const matches = useMediaQuery('(min-width:600px)');
  // console.log("matches:  ", matches);
  return (
    <Container className="container-web">
      <Grid container justifyContent="center">
        <TabContext value={value}>

          <div className="portada-back">
            <Grid container justifyContent="center" alignItems="center" textAlign="center" minHeight="90%">

              <div className="portada-title">
                {/* <Logo className="portada-logo-title" /> */}
                <div 
                className="portada-logo footer-perunpdb">
                  <span className="footer-perunpdb-first">
                    PERU
                  </span>
                  <span className="footer-perunpdb-second">
                    NPDB
                  </span>
                </div>
                <div className="portada-texto"> {language[lang].title}</div>
              </div>
              {/* <div className="portada-box"> */}
              <div className="portada-center">
                <Tabs
                  indicatorColor="null"
                  visibleScrollbar={false}
                  value={value}
                  spacing={3}
                  onChange={handleChange}
                  centered
                  className={`${classes.tabs} portada-box`}>

                  <Tab className={`${myStyle(0)} portada-box-item`} md="6" label={language[lang].compound} />

                  <Tab className={`${myStyle(1)} portada-box-item`} md="6" label={language[lang].list} />

                  <Tab className={`${myStyle(2)} portada-box-item`} md="6" label={language[lang].image} />
                </Tabs>
              </div>
            </Grid>
          </div>

          {MostrarBox()}

        </TabContext>

        <div style={{ padding: "5px" }} />
      </Grid>
    </Container>
  )
}

export default Portada