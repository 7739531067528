import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReactComponent as ImageLogo } from '../../assets/logoNav.svg'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import { useNavigate } from "react-router-dom";
import { language } from '../../language/index';
import { useSelector } from 'react-redux';
import LanguagePopover from 'src/layouts/dashboard/LanguagePopover';
import "./mynav.css"
import { Grid } from '@mui/material';


const drawerWidth = 240;
function NavBarMain(props) {
    const lang = useSelector((state) => state.lang.value)
    const navItems = [language[lang].back];
    const navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const mytheme = createTheme({
        palette: {
            primary: {
                main: '#fff',
            },

        },
    });
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography sx={{ my: 2 }} noWrap>
                <ImageLogo className="nav-logo" />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} onClick={() => navigate("/")} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <ListItem key="lang" disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                    <LanguagePopover />
                </ListItemButton>
            </ListItem>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <ThemeProvider theme={mytheme}>

                <AppBar component="nav">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { sm: 'block' } }}
                        >
                            <ImageLogo className="nav-logo" />

                        </Typography>
                        <Grid style={{ width: 'auto', "min-width": "fit-content" }} container justifyContent="space-between" alignItems="center" textAlign="center" spacing="2" sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <LanguagePopover />

                        </Grid>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {navItems.map((item) => (
                                <Button key={item} className="nav-btn" onClick={() => navigate("/")} startIcon={<ArrowBackIosNewSharpIcon />}>
                                    {item}
                                </Button>
                            ))}
                        </Box>

                    </Toolbar>
                </AppBar>
            </ThemeProvider>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>
        </Box>
    );
}

NavBarMain.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default NavBarMain;
