import { Container, Grid } from '@mui/material'
import React from 'react'

const Muestras = ({ idioma }) => {
  return (

    <div className="my-container" style={{ paddingTop: "0px" }}>
      {/* <Container> */}
      <Grid container >
        <Grid item xs>
          <div className="container-muestras">
            <div className="muestras-info">
              <div className="muestra-number">
                <span>280</span>
                <span className="muestra-number-second">+</span>
              </div>
              <div className="muestra-title">{idioma.muestrasTitle}</div>
              <div className="muestra-description">{idioma.muestrasSubtitle}</div>
              <p className="texto-background">{idioma.textBack}</p>
            </div>
          </div>

        </Grid>
      </Grid >
      {/* </Container> */}
    </div >
  )
}

export default Muestras