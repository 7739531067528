import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from 'src/redux/slices/languageReducer';
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'eng',
    label: 'English',
    icon: '/static/icons/usa-flag-round-circle-icon.svg',
  },
  {
    value: 'spa',
    label: 'Spanish',
    icon: '/static/icons/spain-flag-round-icon.svg',
  },
 
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const lang = useSelector((state) => state.lang.value)
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (idioma) => {
    if (idioma._reactName == undefined) {
      dispatch(changeLanguage(idioma))
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        // className="nav-btn"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 64,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        {/* {lang != undefined ? lang.toUpperCase() : "no encontrado"} */}
        {/* {lang.toUpperCase()} */}
        <img src={LANGS[lang=="spa"?1:0].icon} width="22px" height="22px" alt={LANGS[0].label} />
        <KeyboardArrowDownOutlinedIcon />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === LANGS[0].value} onClick={() => handleClose(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
