import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ImageMol from "../assets/mol-example.png"
import "./css/searchMoltem.css"
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { language } from 'src/language';
import { useSelector } from 'react-redux';
import SmilePicture from './SmilePicture';
import { Jsme } from "jsme-react"
import useMediaQuery from '@mui/material/useMediaQuery';


const SearchMolItem = ({ mol }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const lang = useSelector((state) => state.lang.value)
    // let title = mol.id_mol.replace(/_/g, " ").toUpperCase()
    let title = mol.id_mol.toUpperCase()
    let subtitle = mol.namemol
    let description = mol.classmol
    let id = mol.id_mol.toLowerCase()
    const defaultImage = `http://hulab.rxnfinder.org/smi2img/${mol.smilemol}/?width=413&height=256`
    let cadena = mol.smilemol
    return (
        <Card sx={{ display: 'flex' }} className="search-mol-card">
            <Container style={{ padding: "0px" }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    className="search-mol-center"
                >
                    <Grid item sm="12" md={8}
                        style={{
                            "overflow-wrap": "break-word",
                            width: "100%",
                            // paddingRight:"10px"
                        }} >

                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography className="recientes-mol-card-title">{title}</Typography>
                            <Typography className="recientes-mol-card-subtitle">
                                Name
                            </Typography>
                            <Typography className="recientes-mol-card-content">{mol.namemol}</Typography>
                            <Typography className="recientes-mol-card-subtitle">
                                Smile
                            </Typography>
                            <Typography className="recientes-mol-card-content" style={{
                                "overflow-wrap": "break-word",
                                width: "100%",
                            }} >
                                {mol.smilemol}
                            </Typography>

                            <Typography className="" style={{ "z-index": "3" }}>
                                <Link className="recientes-mol-card-link" to={`compuesto/${id}`} target="_blank"> {language[lang].seeCompound}</Link>
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item sm="12" md={4} style={{ padding: "10px" }}
                    >
                        {/* <iframe
                        className="search-mol-item"
                        src={defaultImage}
                        title={title}
                        /> */}

                        {/* <CardMedia
                            className="search-mol-item"
                            component="img"
                            sx={{ width: 250 }}
                            image={defaultImage}
                            alt={title}
                        /> */}
                        {/* <SmilePicture
                            smile={cadena}
                            width={"100%"}
                            height={"255px"}
                        /> */}
                        <div style={{
                            position: "absolute",
                            right: "0px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "white",
                            bottom: "10px",
                            zIndex: "1",
                        }} />
                        <div
                            style={{ pointerEvents: "none", zIndex: "0" }}
                        >

                            <Jsme
                                // className="jsme-library"
                                width={matches ? "100%" : "200px"}
                                height={matches ? "250px" : "240px"}
                                options="depict,useopenchemlib,noxbutton,noreaction "
                                // options="depict,useopenchemlib"
                                //   height: PropTypes.string.isRequired,
                                // width: PropTypes.string.isRequired,
                                smiles={cadena}
                            // options: PropTypes.string,
                            // onChange: PropTypes.func,
                            // src: PropTypes.string 
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            {/* <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="search-mol-center"
            >
                <Grid item>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography className="recientes-mol-card-title">{title}</Typography>
                    <Typography className="recientes-mol-card-subtitle">
                        Name
                    </Typography>
                    <Typography className="recientes-mol-card-content">{mol.namemol}</Typography>

                    <Typography className="recientes-mol-card-subtitle">
                        Smile
                    </Typography>
                    <Grid item style={{
                        "overflow-wrap": "break-word",
                        width: "100%",
                    }} >
                        <Typography className="recientes-mol-card-content" >
                            {mol.smilemol}
                        </Typography>
                    </Grid>
                    <Typography className="" >
                        <Link className="recientes-mol-card-link" to={`compuesto/${id}`} target="_blank"> ver modelo</Link>
                    </Typography>
                    </CardContent>
                </Grid>
                <Grid item
                    style={{ padding: "10px" }}
                >
                    <CardMedia
                        className="search-mol-item"
                        component="img"
                        sx={{ width: 258 }}
                        image={defaultImage}
                        alt="Live from space album cover"
                    />
                </Grid>
            </Grid> */}
        </Card>
    );
}
export default SearchMolItem;