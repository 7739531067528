import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Background from "../assets/background-footer.png"
import { ReactComponent as Mapa } from "../assets/map.svg"
// import { ReactComponent as Logo} from "../assets/perunpbd-white.svg"
import LogoWhite from "../assets/logo-white.svg"
import "./css/footer.css"
import { language } from "../language"
import { useSelector } from 'react-redux'

const Footer = () => {
  const lang = useSelector((state) => state.lang.value)
  return (

    <div className="image-background" style={{ backgroundImage: `url(${Background})` }}>
      <div className="opacity content-footer">
        <Container style={{ color: "white", padding: " 40px 3.5rem 40px 3.5rem" }}>
          <Grid container>
            <Grid item lg="6" sm="12">
              <Grid container justifyContent="center">
                <Grid item lg="6" sm="6" justifyContent={"end"} alignItems={"center"} display="flex"> <Mapa style={{
                  width: "75px",
                }} />
                </Grid>
                <Grid item lg="6" sm="6" style={{
                  padding: "0px 0px 0 10px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%"
                }}>
                  <h1 className="footer-perunpdb">
                    <span className="footer-perunpdb-first">
                      PERU
                    </span>
                    <span className="footer-perunpdb-second">
                      NPDB
                    </span>
                  </h1>
                  <p>{language[lang].title}</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg="6" sm="12" justifyContent={"center"} alignItems={"center"} display="flex" >
              <div style={{
                padding: "0px 1rem 0 0px"
              }} >
                <p>
                  <h5 className="footer-perunpdb" style={{ paddingBottom: "32px" }}>
                    <span className="footer-perunpdb-first">
                      PERU
                    </span>
                    <span className="footer-perunpdb-second">
                      NPDB
                    </span>
                  </h5>
                </p>
                <p>
                  <b>{language[lang].website}:</b> www.perunpdb.com.pe<br />
                  <b>{language[lang].email}:</b> {"contacto@perunpdb.com.pe"}<br />
                  <b>{language[lang].phone}:</b> {"054-382038"}<br />
                  <b>{language[lang].address}:</b> {"Campus central - Urb. San José s/n Umacollo Arequipa - Perú"}
                </p>
                {language[lang].terms1}
                
                <a
                      style={{ color: "white" }}
                      target="_blank"
                      href={process.env.PUBLIC_URL + "terminos.html"}
                    >
                      {language[lang].terms2}
                    </a>
                
                {/* <Link style={{ color: 'white' }} to="/#/terminos" target="_blank">
                  {language[lang].terms2}
                </Link> */}
              </div>
          </Grid>
        </Grid>
      </Container>
    </div >
    </div >
  )
}

export default Footer