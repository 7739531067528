import { Container, Grid } from '@mui/material'
import React from 'react'
import imgGonzalo from '../../assets/Gonzalo.png'
import imgLena from '../../assets/Lena.png'
import imgMiguel from '../../assets/Miguel.png'
import imgHaruna from '../../assets/Haruna.png'
const dates = [
  {
    image: imgMiguel,
    title: "PhD Miguel Chavez Fumagalli",
    subtitle: "Solo es un subtitlle 1"
  }, {
    image: imgHaruna,
    title: "PhD Haruna L. Barazorda Ccahuana",
    subtitle: "Solo es un subtitlle 1"
  }, {
    image: imgLena,
    title: "PhD Lena Galvez Ranilla",
    subtitle: "Solo es un subtitlle 1"
  }, {
    image: imgGonzalo,
    title: "PhD Gonzalo Hermilio Dávila Del Carpio",
    subtitle: "Solo es un subtitlle 1"
  }

]
const Equipo = ({ idioma }) => {
  return (
    <div style={{ backgroundColor: "#fff" }} name="team">
      <Container fixed className="my-container" >
        <Grid container justifyContent="center" alignItems="center" textAlign="center" minHeight="100%">
          <div className="my-title-center">{idioma.team}</div>

          <Grid item xs="12">
            {/* <Avatar
              image={imgGonzalo}
              title={"Israel Santiago Pancca Mamani"}
              subtitle={"Licenciado ciencia de la computación"} /> */}

          </Grid>

          {
            dates.map((date, i) => {
              return (
                <Grid item key={i} lg="3" md="6" >
                  <Avatar
                    image={date.image}
                    title={date.title}
                    subtitle={""} />
                </Grid>
              )
            })
          }

        </Grid>
      </Container>
    </div>
  )
}

const Avatar = ({ image, title, subtitle }) => {
  return (
    <div className="equipo-image-user">
      <div className="equipo-image">
        <div className="equipo-border" />
        <img className="equipo-photo" src={image} alt={title} />
      </div>
      <div className="equipo-user-title">{title}</div>
      <div className="equipo-user-subtitle">{subtitle}</div>
    </div>
  )
}
export default Equipo