const url = "https://perunpdb.com.pe/api/molApp/mailer/"
import axios from "axios"


export const messageInfo = (message) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.post(`${url}`, message)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}


