export const language = {
    spa: {
        back:"Regresar",
        filters:"Filtros",
        textBack:"Compuesto biológico",
        recent: "Busquedas Recientes",
        suggestions: "Sugerencias",
        btnSearch: "Buscar",
        title: "Base de Datos Peruana de Productos Naturales",
        contactNav: "Contacto",
        usNav: "El proyecto",
        login: "INICIAR SESIÓN",
        compound: "Compuesto",
        list: "Lista",
        image: "Imagen",
        team: "Equipo técnico",
        appMovil: "Aplicación Mobil",
        appMovilTitle: "Navega y encuentra muestras de compuestos generadas en el Perú",
        appMovilDescription: "Más de 150 muestras categorizadas, ordenadas, por listas, mapas y etiquetas",
        appDownload: "Descargar App",
        contact: "Contáctanos",
        contactDescription: "Si deseas solicitar la información de las muestras, solicitarla por medio de una declaración jurada, y adjuntarle en tu mensaje.",
        name: "Nombre",
        email: "Email",
        university: "Universidad",
        message: "Mensaje",
        messagePlaceholder:"Escribe aqui tu mensaje",
        sendMessage: "Enviar Correo",
        muestrasTitle: "Muestras de compuestos",
        muestrasSubtitle: "Más de 280 muestras categorizadas, ordenadas, por listas, mapas y etiquetas, disponibles para fines investigativos",
        aboutName: "Base De Datos Peruana de Productos Naturales",
        aboutProject: "Proyecto",
        aboutContenido: "Peruvian Natural Product Database (PERUNPDB) es una base de datos de acceso abierto de metabolitos de plantas peruanas reportados en varias colecciones. PERUNPDB es la primera versión de una colección de más de 200 compuestos de origen peruano donde el 95% de los compuestos provienen de plantas y el 5% de animales. Los recursos están disponibles para su descarga gratuita sin restricciones.",
        dontResults: "0 Compuestos encontrados",
        imageText1: "Para realizar una busqueda escriba los compuestos asi:",
        imageText2: "Luego presione el boton buscar",
        seeCompound: "Ver compuesto",

        website: "Página web",
        email: "Correo institucional",
        phone: "Teléfono",
        address: "Dirección",
        terms1: "Revisa aquí los ",
        terms2: "Terminos, Condiciones De Uso y Propiedad Intelectural",

    },
    eng: {
        back:"Back",
        filters:"Filters",
        textBack:"Biological compound",
        recent: "Recent",
        suggestions: "Suggestions",
        btnSearch: "Search",
        title: "Peruvian Natural Products Database",
        contactNav: "Contact Us",
        usNav: "The Project",
        login: "LOGIN",
        compound: "Compound",
        list: "List ",
        image: "Image",
        team: "Technical Team",
        appMovil: "Mobil Application",
        appMovilTitle: "Browse and find samples of compounds generated in Peru",
        appMovilDescription: "More than 150 samples categorized, sorted, by lists, maps and labels",
        appDownload: "Download App",
        contact: "Contact",
        contactDescription: "If you wish to request sample information, request it by means of an affidavit, and attach it to your message.",
        name: "Name",
        email: "Mail",
        university: "University",
        message: "Message",
        messagePlaceholder:"Write here your message",
        sendMessage: "Send Mail",
        muestrasTitle: "Compound Samples",
        muestrasSubtitle: "More than 280 samples categorized, ordered, by lists, maps and labels, available for research purposes",
        aboutName: "Peruvian Natural Products Database",
        aboutProject: "Project",
        aboutContenido: "Peruvian Natural Product Database (PERUNPDB) is a curated open-access search database of Peruvian plant metabolites reported in various collections. PERUNPDB is the first version of a collection of more than 200 compounds of Peruvian origin where 95% of the compounds come from plants and 5% from animals. The resources are available for free download without restriction.",
        dontResults: "0 Compounds found",
        imageText1: "To perform a search write the compounds like this:",
        imageText2: "Then press the search button",
        seeCompound: "See Compound",

        website: "Website",
        email: "Email",
        phone: "Phone",
        address: "Address",
        terms1: "Review here ",
        terms2: "Terms, Conditions of Use and Intellectual Property",
    },

}