import { LoadingButton } from '@mui/lab'
import { Alert, Container, Divider, Grid, TextareaAutosize, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { messageInfo } from "../../actions/messageAction"


const Contacto = ({ idioma }) => {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    firstname: "",
    email: "",
    university: "",
    message: "",

  })
  const [message, setMessage] = useState({
    subject: "",
    message: "",
    email: ""
  })
  const [registerResult, setRegisterResult] = useState({
    severity: "info",
    text: "",
    active: false
  })

  const init = () => {
    setValues({
      firstname: "",
      email: "",
      university: "",
      message: "",
    })
    // setRegisterResult({
    //   severity: "info",
    //   text: "",
    //   active: false
    // })
    setMessage({
      subject: "",
      message: "",
      email: ""
    })

  }
  const handleChange = (event) => {
    let { name, value } = event.target
    setValues({ ...values, [name]: value });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("mostrado", values);
    let men = "Universidad: " + values.university + ", Mi mensaje es: " + values.message
    setMessage({
      subject: "Información",
      message: men,
      email: values.email
    })
    let response = messageInfoAsync()
    response.then(res => {
      console.log("enviado")
      setRegisterResult({
        ...registerResult,
        severity: "success",
        text: "Mensaje enviado",
        active: true
      })
      init();
    }).catch(err => {
      console.log("error", err)
      setRegisterResult({
        ...registerResult,
        severity: "error",
        text: "No se ha podido enviar el mensaje",
        active: true
      })
    })
  }
  const messageInfoAsync = async () => {
    let res = {}
    res = await messageInfo(message)
    return res;
  }




  return (
    <div style={{ background: "#fff" }} name="contact">

      <Container className="container-web my-container">
        <Typography className="my-title-left">
          {idioma.contact}
        </Typography>
        <Typography className="contact-description">
          {idioma.contactDescription}
          {/* Si deseas solicitar la informacion de las muestras, solicitarla por medio de una declaracion jurada, y adjuntarla en tu mensaje. */}
        </Typography>
        <form onSubmit={handleOnSubmit} >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={6}>
              <div className="contact-label">{idioma.name}</div>
              <TextField
                id="outlined-basic"
                // variant="outlined"
                required
                name="firstname"
                type="text"
                placeholder="Ricardo Paredes"
                className="contact-input"
                onChange={handleChange}
              />
              <div className="contact-label">{idioma.email}</div>
              <TextField
                id="outlined-basic"
                // variant="outlined"
                required
                name="email"
                type="email"
                placeholder="rparedes@gmail.com"
                className="contact-input"
                onChange={handleChange}
              />
              <div className="contact-label">{idioma.university}</div>
              <TextField
                required
                type="text"
                name="university"
                placeholder="Universidad Católica de Santa María"
                className="contact-input"
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <div className="contact-label">{idioma.message}</div>
              <TextareaAutosize
                // minRows="3"
                onChange={handleChange}
                name="message"
                required
                placeholder={idioma.messagePlaceholder}
                className="contact-input"
                style={{ width: '100%', height: "310px", padding: "16.5px 14px" }}
              />
            </Grid>
            <Grid item sm="12" xs={12} style={{ textAlign: "end", padding: "30px 0px 30px 0px" }}>
              <LoadingButton
                className="login-button"
                size="large"
                type="submit"
                color="secondary"
                loading={loading}
                loadingPosition="start"
                variant="contained"
              >
                {idioma.sendMessage}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} hidden={!registerResult.active}>
              <Alert severity={registerResult.severity}>{registerResult.text}</Alert>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default Contacto