import axios from "axios"
const apiSearch = "https://perunpdb.com.pe/api/molApp/sea/"
let urlApi = "https://perunpdb.com.pe/api/molApp/molecule/"

export const getCompuestoID = (id) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.get(`${urlApi}${id}/`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}


export const getCompuestosPage = (page = 1) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.get(`${urlApi}?page=${page}`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}

export const getCompuestosSearch = (url) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.get(`${url}`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const getCompuestosFilters = (url, filtros) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return new Promise((resolve, reject) => {
        axios.post(`${url}`, filtros, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}


export const getCompuestosFilters2 = (id) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    let url = "https://perunpdb.com.pe/api/molApp/getmolbyid/" + id + "/";
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(`${url}`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}

export const getFilters = () => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${apiSearch}?ordering=${order}&page=${page}&search=${text}`)
        //console.log("url", url)
        axios.get("https://perunpdb.com.pe/api/molApp/listFilters/", head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}
export const addCompuesto = (token, compound) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(`https://perunpdb.com.pe/api/molApp/molecule/`, compound, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const deleteCompuesto = (token, id) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }

    return new Promise((resolve, reject) => {
        axios.delete(`https://perunpdb.com.pe/api/molApp/molecule/${id}/`, head)
            .then(function ({ data }) {
                resolve(true);
            })
            .catch(function (error) {
                reject(error);
            });
    });

}
export const editCompuesto = (token, compuesto, id) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.put(`https://perunpdb.com.pe/api/molApp/molecule/${id}/`, compuesto, head)
            .then(function ({ data }) {
                resolve(data);
            })
            .catch(function (error) {
                console.log("error actions ", error)
                reject(error);
            });
    });
}

export const getCSV = () => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.get(`https://perunpdb.com.pe/api/molApp/exportMolecule/`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}
export const uploadCSVCompuestos = (token, filecsv) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            'Content-Type': 'multipart/form-data'
        }
    }
    var formData = new FormData();
    formData.append("file", filecsv);

    return new Promise((resolve, reject) => {
        axios.post(`https://perunpdb.com.pe/api/molApp/csvupMolecule/`, formData, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

// var formData = new FormData();
// var imagefile = document.querySelector('#file');
// formData.append("image", imagefile.files[0]);
// axios.post('upload_file', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
// })