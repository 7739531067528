import * as React from 'react';
import AppMobil from './AppMobil'
import CompuestosRecientes from './CompuestosRecientes'
import Contacto from './Contacto'
import Equipo from './Equipo'
import Informacion from './Informacion'
import Muestras from './Muestras'
import "./css/index.css"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';
import ImageMol from "../../assets/mol-example.png"
// import { getCompuestosSearch } from "../../actions/compuestosAction"
import "./css/compuestosRecientes.css"
import { Link } from 'react-router-dom';
import { language } from "../../language"
import { useSelector } from 'react-redux';
import { getCompuestosFilters } from "../../actions/compuestosAction"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { getCompuestosPage, getFilters } from 'src/actions/compuestosAction'
import SmilePicture from '../SmilePicture';
import { Jsme } from "jsme-react"
import useMediaQuery from '@mui/material/useMediaQuery';


const Index = ({ texto }) => {
    const lang = useSelector((state) => state.lang.value)
    const [data, setData] = React.useState([])
    // const [count, setCount] = React.useState(0)
    // const [text, setText] = React.useState("")
    // const [order, setOrder] = React.useState("id_mol")
    // const [paginado, setPaginado] = React.useState(false)
    const [next, setNext] = React.useState(null);
    const [count, setCount] = React.useState(0)
    const [chips, setChips] = React.useState([]);
    const [checked, setChecked] = React.useState(null);
    const [filtros, setFiltros] = React.useState({
        "Search": texto,
        "Orderby": "id_mol",
        "Filters": {
            classmol: [],
            genusmol: [],
            journalmol: [],
            kingdommol: [],
            speciemol: [],
            yearrefmol: []
        }
    })
    const handleToggle = (key, value) => {
        // console.log("ingresando")
        let currentIndex = -1
        let newChecked = []
        if (checked != null) {
            currentIndex = checked[key].indexOf(value);
            newChecked = [...checked[key]];
        }

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked({
            ...checked,
            [key]: newChecked
        });
    };
    React.useEffect(() => {
        // console.log(checked);
        // var size = Object.keys(checked).length;
        // console.log("siez", size)
        // if (size > 0) {
        getListaFilter(checked);
        // console.log(checked);
        // console.log("esta ingresando")
        // }
    }, [checked])
    const createLista = (filtrado) => {
        let ls = {}
        _.map(filtrado, (elements, key) => {
            ls = { ...ls, [key]: [] }
        })
        setChecked(ls)
    }

    const getListaFilter = (res) => {
        let newList = {}
        _.map(res, (val, key) => {
            let k = ""
            if (key === "year") k = "yearrefmol"
            else k = key + "mol"
            newList = { ...newList, [k]: val }
        })
        // console.log("filtrados", newList)
        setFiltros({
            ...filtros,
            "Filters": newList
        })
    }

    // const getDataSearchAsync = async (link) => {
    //     let res = []
    //     res = await getCompuestosSearch(link)
    //     return res
    // }
    // const getData = (link) => {
    //     let response = getDataSearchAsync(link)
    //     response.then(res => {
    //         UpdateStatesMore(res.data)
    //     }).catch(error => {
    //         console.log("error")
    //     })
    // }
    // const UpdateStatesMore = (response) => {
    //     setCount(response.count)
    //     setNext(response.next)
    //     setData([...data, ...response.results])
    // }
    // React.useEffect(() => {
    //     let link = (`http://134.122.125.35/molApp/sea/?ordering=${order}&page=1&search=${text}`)
    //     getData(link)
    // }, [])
    // const resetAll = () => {
    //     setData([])
    //     setCount(0)
    //     setNext(null)
    // }
    // React.useEffect(() => {
    //     let link = (`http://134.122.125.35/molApp/sea/?ordering=${order}&page=1&search=${text}`)
    //     getData(link)
    // }, [text])
    // React.useEffect(() => {
    //     if (texto != text) {
    //         resetAll()
    //         setText(texto)
    //     }
    // }, [texto])

    const getDataFiltersAsync = async (link) => {
        let res = []
        res = await getCompuestosFilters(link, filtros)
        return res
    }

    const getData = (link) => {
        let response = getDataFiltersAsync(link)
        response.then(res => {
            // console.log(res.data)
            UpdateStatesMore(res.data)
            // if (res.data.count > 10)
            //   almacenar(link,filtros.Orderby)
        }).catch(error => {
            console.log("error", error)
        })
    }
    const UpdateStatesMore = (response) => {
        setCount(response.count)
        setNext(response.next)
        //setData([...data, ...response.results])
        setData([...response.results])
    }
    React.useEffect(() => {
        setFiltros({
            ...filtros,
            "Search": texto,
        })
        // console.log("---filtros", filtros)
    }, [texto])

    React.useEffect(() => {
        let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
        getData(link)
    }, [filtros])
    // React.useEffect(() => {
    //     const filterss = JSON.parse(localStorage.getItem('filtro'));
    //     let link = (`http://134.122.125.35/molApp/sea/?page=1`)
    //     if (filterss != null)
    //         setFiltros(filterss)
    //     getData(link)
    // }, [localStorage.getItem('filtro')])
    const filtersss = JSON.parse(localStorage.getItem('filtro'));
    // console.log("----", filtersss)
    const handleClick = (item) => {
        // console.info('You clicked the Chip.', item);
        // let res= chips
        let res = chips.map(ele => {
            if (ele.value == item.value) return { ...ele, "activo": !item.activo }
            return ele
        })
        setChips(res)
    };

    const generateChips = () => {
        let filtros = getFiltersAsync();
        filtros.then(res => {
            createLista(res.data.Filters)
            // console.log(res.data.Filters)
            let lista = _.map((res.data.Filters), (value, key) => {
                return value.map(ele => { if (ele.length <= 13) return { "value": ele, "key": key, "activo": false } })
            })

            // console.log("lista", lista)
            let results = lista.flat(1)
            results = results.filter(element => {
                return element !== undefined;
            });
            // console.log(results)
            let f = []
            for (let i = 0; i < 20; i++) {
                let item = results[Math.floor(Math.random() * results.length)]

                let existe = f.find(ele => ele.value == item.value)
                if (!existe) {
                    f.push(item)
                }

            }
            // console.log("----f",f)
            // let unique = f.filter((v, i, a) => a.indexOf(v.value) === i.value);
            setChips(f)
            //console.log("---lista--", unique.length)
            // let lista = (res.data.Filters).reduce

        }).catch(error => {
            console.error(error)
        })
    }
    const getFiltersAsync = async () => {
        let res = {};
        res = await getFilters()
        return res;
    }
    React.useEffect(() => {
        generateChips();
    }, [])
//     React.useEffect(() => {
//         window.onscroll = () => {
//           const scrollHeight = document.documentElement.scrollWidth
//           const currentHeight =
//             window.innerWidth + document.documentElement.scrollTop;

// console.log("scrollHeight",scrollHeight)
// console.log("currentHeight",currentHeight)

//           if (currentHeight + 1 >= scrollHeight) {
//             console.log("ingresa")
//             // setPaginado(true)
//           }
    
//         };
//       });
    return (
        <div>
            <Container className="container-sugerencias">
                <div className="chips-container">
                    <div >{language[lang].suggestions}</div>
                    <Grid container spacing={1} className="chips-container-items">
                        <Stack direction="row" spacing={2}>
                            {chips.map(ele => {
                                return <Chip className={ele.activo ? "mychip-active" : "mychip"} label={ele.value} variant="outlined" onClick={() => {
                                    handleToggle(ele.key, ele.value)
                                    handleClick(ele)
                                }} />
                            })}
                            {/* <Chip label="Clickable" onClick={handleClick} /> */}
                        </Stack>
                    </Grid>
                </div>
            </Container>
            {/* <CompuestosRecientes /> */}
            <Container className="container-sugerencias-items" style={{ backgroundColor: "#F9F9F9" }}>
                <div className="recientes-container">
                    <div style={{ paddingTop: "48px" }}>{language[lang].recent}</div>
                    {/* poner paginado */}
                    <Grid container spacing={1} className="recientes-container-items">
                        {data.length > 0 ? (
                            data.map((row, i) => (
                                <ItemMolCard mol={row} />
                            ))
                        ) : (<div className="search-0">
                            {language[lang].dontResults}
                        </div>)
                        }
                    </Grid>
                </div>
            </Container>
            <Informacion idioma={language[lang]} />
            <Muestras idioma={language[lang]} />
            <Equipo idioma={language[lang]} />
            <AppMobil idioma={language[lang]} />
            <Contacto idioma={language[lang]} />
        </div>
    )
}

const ItemMolCard = ({ mol }) => {
    const lang = useSelector((state) => state.lang.value)
    const matches = useMediaQuery('(min-width:600px)');

    let title = mol.id_mol.toUpperCase()
    //let subtitle = mol.namemol
    let description = mol.classmol
    let id = mol.id_mol.toLowerCase()
    const defaultImage = `http://hulab.rxnfinder.org/smi2img/${mol.smilemol}/?width=413&height=256`
    let cadena = mol.smilemol
    let smiles = mol.smilemol
    if(smiles.length>70){
        smiles = smiles.substring(0, 70) + "...";
    } 
    let namemol = mol.namemol
    // if(namemol.length>40){
    //     namemol = namemol.substring(0, 37) + "...";
    // }// console.log("-->", mol.smilemol)
    return (
        <div>


            <Card className="recientes-mol-card">
                <Container>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="search-mol-center"
                    >
                        <Grid item sm="12"
                        >
                            {/* <SmilePicture
                                smile={mol.smilemol}
                                width={"100%"}
                                height={"255px"}
                            /> */}

                            
                            <div
                                style={{ pointerEvents: "none", zIndex: "0" }}
                            >
                                <Jsme
                                    className="jsme-library"
                                    width={matches ? "365px" : "280px"}
                                    height={matches ? "225px" : "205px"}
                                    options="depict,useopenchemlib"
                                    //   height: PropTypes.string.isRequired,
                                    // width: PropTypes.string.isRequired,
                                    smiles={cadena}
                                // options: PropTypes.string,
                                // onChange: PropTypes.func,
                                // src: PropTypes.string 
                                />
                                {/* <CardMedia
                            component="img"
                            alt="green iguana"
                            image={defaultImage}
                            className="recientes-mol-card-img"
                        /> */}

                            </div>
                            <div style={{
                                position: "absolute",
                                right: "0px",
                                width: "100%",
                                height: "10px",
                                backgroundColor: "white",
                                zIndex: "1",
                                 marginTop: "-10px"
                            }} />
                        </Grid>
                        <Grid item sm="12" md={12}
                            style={{
                                "overflow-wrap": "break-word",
                                width: "100%",
                            }} >

                            <CardContent sx={{ flex: '1 0 auto',p:0 }}>
                                <Typography className="recientes-mol-card-title">{title}</Typography>
                                <Typography className="recientes-mol-card-subtitle">
                                    Name
                                </Typography>
                                <Typography className="recientes-mol-card-content" style={{
                                    "overflow-wrap": "break-word",
                                    width: "100%",
                                }} >{namemol}</Typography>
                                <Typography className="recientes-mol-card-subtitle">
                                    Smile
                                </Typography>

                                <Typography className="recientes-mol-card-content" style={{
                                    "overflow-wrap": "break-word",
                                    width: "100%",
                                }} >
                                    {smiles}
                                </Typography>
                                <Typography className="" >
                                    <Link className="recientes-mol-card-link" to={`compuesto/${id}`} target="_blank"> {language[lang].seeCompound}</Link>
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Container>
            </Card>
        </div>
    );
}
export default Index