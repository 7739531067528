// userReducer.js
import { createSlice } from '@reduxjs/toolkit'

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const expiry = localStorage.getItem('expiry')
    ? localStorage.getItem('expiry')
    : null
const userInfo = localStorage.getItem('userInfo')
    ? localStorage.getItem('userInfo')
    : null

const initialState = {
    loading: false,
    userInfo,
    userToken,
    expiry,
    error: null,
    success: false,
}

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        activeLoading: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.loading = true
        },
        inactiveLoading: (state) => {
            state.loading = false
        },
        saveToken: (state, action) => {
            localStorage.setItem('userToken', action.payload)
            return {
                ...state,
                userToken: action.payload
            }
        },
        saveExpiry: (state, action) => {
            localStorage.setItem('expiry', action.payload)
            return {
                ...state,
                expiry: action.payload
            }
        },
        saveUser: (state, action) => {
            localStorage.setItem('userInfo', JSON.stringify(action.payload))
            return {
                ...state,
                userInfo: JSON.stringify(action.payload)
            }
        },
        logout: (state) => {
            localStorage.removeItem('userToken') // deletes token from storage
            localStorage.removeItem('userInfo') // deletes token from storage
            localStorage.removeItem('expiry') // deletes token from storage
            state.loading = false
            state.userInfo = null
            state.userToken = null
            state.error = null
            state.expiry = null
        },
    },
    extraReducers: {
        // userLogin reducer ...
        // registerUser reducer ...
        // getUserDetails reducer ...
    },
})
// export actions
export const { activeLoading, inactiveLoading, saveToken, logout, saveExpiry, saveUser } = userReducer.actions
export default userReducer.reducer