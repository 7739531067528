import React, { useState } from 'react'
import { useParams } from 'react-router'
import NavBarCompuesto from '../components/NavBar/NavBarCompuesto'
import Box from '@mui/material/Box';
import { getCompuestoID } from '../actions/compuestosAction';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import CssBaseline from '@mui/material/CssBaseline';
// css
import "./css/compuestoID.css"
import { styled } from '@mui/material/styles';
import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import Footer from './Footer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Jsme } from 'jsme-react';
import { getCompuestosFilters2 } from "../actions/compuestosAction"

const Compuesto = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const params = useParams()
  const id = params.id;
  const [mol, setMol] = useState(null)
  const getMolAsync = async () => {
    let d = []
    d = await getCompuestoID(id);
    return d;
  }
  // const newImageURL = (name) => {
  //   return `http://hulab.rxnfinder.org/smi2img/${name}/?width=500&height=600`
  // }
  const getSearch = async () => {
    let res = null;
    // let link = `https://perunpdb.com.pe/api/molApp/sea/?page=1`
    // res = await getCompuestosFilters(link, {
    //   "Search": id,
    //   "Orderby": "id_mol",
    //   "Filters": {
    //     classmol: [],
    //     genusmol: [],
    //     journalmol: [],
    //     kingdommol: [],
    //     speciemol: [],
    //     yearrefmol: []
    //   }
    // })
    res = await getCompuestosFilters2(id)
    return res

  }
  const readMol = () => {
    let search = getSearch()
    search.then((res) => {
      console.log("res", res);
      let comp = res.data
      // console.log("-zzzz", res.data.results[0])
      setMol(comp)
      if (comp == undefined) {
        setMol(null);
        navigate("/404")
      }

    }).catch(error => {
      // console.log("error", error);
      if (error == null) {
        setMol(null);
        navigate("/404")

      }
    })
    // console.log("read mol")
    // let response = getMolAsync()
    // response.then(data => {
    //   console.log("data", data.data)
    //   setMol(data.data)
    //   // let defaultImage = `http://hulab.rxnfinder.org/smi2img/${mol.smilemol}/?width=500&height=600`
    //   // const res = [
    //   //   {
    //   //     original: defaultImage,
    //   //     thumbnail: defaultImage,
    //   //   },
    //   // ];
    //   // setMolImage(res)
    //   // setImages(res)
    // }).catch(error => {
    //   // console.log("error", error);
    //   if (error == null) {
    //     setMol(null);
    //     navigate("/404")

    //   }
    // })
  }
  useEffect(() => {
    readMol();
  }, [id])

  return (
    <>
      {(mol == null) ?
        (<Grid container justifyContent="center" alignItems="center" textAlign="center" minHeight="100%">
          <Grid >
            <CircularProgress disableShrink />
          </Grid>
        </Grid>
        ) : (
          <div>
            <CssBaseline />
            <NavBarCompuesto />
            <Container fixed style={{ paddingBottom: "60px" }}>
              <Grid container justifyContent="center" alignItems="center" textAlign="center" minHeight="100%" flexDirection="column">
                <Grid className="compuesto-title"> {mol.id_mol.toUpperCase()}</Grid>
                <Grid className="compuesto-subtitle">{mol.namemol}</Grid>
                {/* <Grid className="compuesto-image" >
                  <ImageGallery
                    items={[
                      {
                        original: newImageURL(mol.smilemol),
                        thumbnail: newImageURL(mol.smilemol),
                      }
                    ]}
                    // defaultImage={defaultImage}
                    showBullets={true}
                    showIndex={true}
                    showThumbnails={false}
                    lazyLoad={true}
                    showPlayButton={false}
                  />
                </Grid> */}
                <Grid className="compuesto-image">
                  <Jsme
                    // className="jsme-library"
                    width={matches ? "500px" : "300px"}
                    height={matches ? "500px" : "360px"}
                    // options="depict,useopenchemlib"
                    options="depict,nopaste,noShowdragandDropIconindepictmode,nocontextmenu,fullScreenIcon,showFullScreenIconInDepictMode"
                    smiles={mol.smilemol}
                  />
                </Grid>





                <Grid style={{
                  "padding-bottom": "40px",
                  "padding-top": "40px",
                  "overflow-wrap": "break-word"
                }} >
                  <Typography component="h6" className="compuesto-name-mol" >
                    {mol.smilemol}
                  </Typography>
                </Grid>

              </Grid>
              <Item
                title="Reference"
                description={mol.referencemol.replace(/_/g, " ")}
              />
              <Item
                title="Year"
                description={mol.yearrefmol}
              />
              <Item
                title="Kingdom"
                description={mol.kingdommol}
              />
              <Item
                title="Genus"
                description={mol.genusmol}
              />
              <Item
                title="DOI"
                description={mol.doimol}
                link={"https://doi.org/" + mol.doimol}
              />
              <Item
                title="Journal"
                description={mol.journalmol}
              />
              <Item
                title="NP Classifier-Class"
                description={mol.classmol}
              />
            </Container>

            <Footer />
          </div>
        )
      }
    </>
  )
}


const Item = ({ title, description, link = "" }) => {
  if (description == null) description = ""
  if (link != "") {
    return (
      <Grid container id={title} className="compuesto-lista">
        <Grid item lg="4" md="4" sm="12" className="item-title">{title}</Grid>
        <Grid item lg="8" md="8" sm="12" className="item-description"><a href={link} target="_blank">{description}</a></Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container id={title} className="compuesto-lista">
        <Grid item lg="4" md="4" sm="12" className="item-title">{title}</Grid>
        <Grid item lg="8" md="8" sm="12" className="item-description">{description}</Grid>
      </Grid>
    )
  }
}


export default Compuesto