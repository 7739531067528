import React, { useEffect, useState } from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import "./css/user.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ButtonBio } from 'src/components/ButtonBio';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { LoadingButton } from '@mui/lab';
import { register, GetUsersSearch, deleteUser, editUser } from "../actions/userAction"
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import {
  FormControl,
  FormLabel,
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Modal,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  OutlinedInput,
  Divider,
  Alert,
  Tooltip,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSelector } from 'react-redux';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80wh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const headers = [
  {
    key: "firstname",
    name: "Nombres"
  }, {
    key: "lastname",
    name: "Apellidos"
  }, {
    key: "email",
    name: "Correos"
  }
]
const initUser = {
  username: "",
  email: "",
  password: "",
  imagecover: "",
  firstname: "",
  lastname: "",
  country: "",
  phone: "",
  adress: "",
  city: "",
  type: 1,
}
const User = () => {
  const token = useSelector((store) => store.user.userToken);
  const userLogin = JSON.parse(useSelector((store) => store.user.userInfo));
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [text, setText] = useState("")
  const [order, setOrder] = useState("firstname")
  const [paginado, setPaginado] = useState(false)
  const [next, setNext] = useState(null);
  // modal create
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false)
  const [newUser, setNewUser] = useState({})
  const [canEliminated, setCanEliminated] = useState(false)
  const [values, setValues] = useState(initUser);
  const [valuesPass, setValuesPass] = useState({
    password: "",
    showPassword: false,
  });
  const [result, setResult] = useState({
    severity: "info",
    text: "",
  })

  const handleOpen = () => setOpen(true)
  const handleOpenAdd = () => {
    setValues(initUser)
    setOpenAdd(true)
  }
  const handleClose = () => setOpen(false);
  const handleCloseAdd = () => setOpenAdd(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleClickShowPassword = () => {
    setValuesPass({
      ...valuesPass,
      showPassword: !valuesPass.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChangePass = (prop) => (event) => {
    setValues({ ...values, "password": event.target.value })
    setValuesPass({ ...valuesPass, [prop]: event.target.value });
  };
  const handleChange = (event) => {
    let { name, value } = event.target
    if (name == "email")
      setValues({ ...values, [name]: value, ["username"]: value });
    else if (name == "firstname" || name == "lastname")
      setValues({ ...values, [name]: value.toUpperCase() });
    else
      setValues({ ...values, [name]: value });
  };
  const handleChangeEdit = (event) => {
    let { name, value } = event.target

    setNewUser({ ...newUser, [name]: value });
  };
  // end create

  const deleteAsync = async (id) => {
    let res = null;
    res = await deleteUser(token, id)
    return res;
  }
  const editAsync = async () => {
    console.log(newUser)
    let res = null;
    res = await editUser(token, newUser, newUser.id)
    return res;
  }
  const handleDelete = (dataUser) => {
    setNewUser(dataUser)
    setOpenDelete(true)
    console.log(dataUser)
    console.log(userLogin)
    setCanEliminated(userLogin.id == dataUser.id)
  }
  const handleOnSubmitDelete = (e) => {
    e.preventDefault();
    let response = deleteAsync(newUser.id)
    response.then((res) => {
      // mensaje de eliminado 
      setOpenDelete(false)
      setNewUser({})
      setResult({
        text: "Se elimino exitosamente",
        severity: "success"
      })
      handleOpen();
      let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
      getDataAction(link)
    }).catch(err => {
      // mensaje de error
      setOpenDelete(false)
      setNewUser({})
      setResult({
        text: "No se pudo eliminar",
        severity: "error"
      })
      handleOpen();
    })
  }
  const handleEdit = (dataUser) => {
    setNewUser(dataUser)
    setOpenEdit(true)
  }
  const handleOnSubmitEdit = (e) => {
    e.preventDefault();
    let response = editAsync()
    response.then((res) => {
      // mensaje de eliminado 
      setOpenEdit(false)
      setNewUser({})
      setResult({
        text: "Se modifico exitosamente",
        severity: "success"
      })
      handleOpen();
      let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
      getDataAction(link)

    }).catch(err => {
      // mensaje de error
      setOpenEdit(false)
      setNewUser({})
      setResult({
        text: "No se pudo modificar",
        severity: "error"
      })
      handleOpen();

    })
  }

  // create new user
  const registerAsync = async (user) => {
    let res = null
    res = await register(user)
    return res;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // setValues({ ...values, "password": valuesPass.password });
    // console.log("values", values)
    let response = registerAsync(values)
    response.then(res => {
      // handleClose()
      setResult({
        text: "Se agrego al usuario exitosamente",
        severity: "success"
      })
      handleCloseAdd();
      handleOpen();
      let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
      getDataAction(link)
      // resetAll()
      // setText("")
      // let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
      // getData(link)
    }).catch(error => {
      console.log("error", error)
      setResult({
        text: "No se pudo agregar al usuario",
        severity: "error"
      })
      handleCloseAdd();
      handleOpen();
      // handleClose()
      // setRegisterResult({
      //   ...registerResult,
      //   severity: "error",
      //   text: "No se ha podido registrar",
      //   active: true
      // })
    })
  }
  const getDataSearchAsync = async (link) => {
    let res = []
    res = await GetUsersSearch(link)
    return res
  }
  const getData = (link) => {
    // console.log("-----", link)
    let response = getDataSearchAsync(link)
    response.then(res => {
      UpdateStatesMore(res.data)
    }).catch(error => {
      console.log("error")
    })
  }
  const getDataAction = (link) => {
    let response = getDataSearchAsync(link)
    response.then(res => {
      // console.log("res")
      setCount(response.count)
      setNext(response.next)
      setData([...res.data.results])
    }).catch(error => {
      console.log("error")
    })
  }
  const UpdateStatesMore = (response) => {
    setCount(response.count)
    setNext(response.next)
    setData([...data, ...response.results])
  }
  const initialData = () => {
    let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
    getData(link)
  }
  useEffect(() => {
    initialData();
  }, [])
  const resetAll = () => {
    setData([])
    setCount(0)
    setNext(null)
  }
  useEffect(() => {
    initialData();
    if (text.length == 0) {
      // console.log("mira vacio text")
    }
  }, [order, text])

  const showHeaders = () => {
    return (
      <>
        <TableHead style={{ backgroundColor: "#F0F2F5" }}>
          <TableRow>
            {
              headers.map((item, id) => {
                return (
                  <TableCell key={id}>
                    <Button className='user-btn-filter'
                      onClick={() => {
                        if (item.key != order) {
                          resetAll()
                          setOrder(item.key)
                        }
                      }}
                      endIcon={<UnfoldMoreIcon />}>{item.name}</Button>
                  </TableCell>
                )
              })
            }
            <TableCell style={{
              "font-weight": "700",
              "line-height": "1.7142857142857142",
              "font-size": "0.875rem",
              color: "#74818F"
            }} align="center">Acción</TableCell>
          </TableRow>
        </TableHead>
      </>
    )
  }

  const showLista = () => {
    return (
      <>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              style={{
                borderBottom: "2.5px solid #f0f2f5"
              }}
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"> {row.firstname}</TableCell>
              <TableCell >{row.lastname}</TableCell>
              <TableCell >{row.email}</TableCell>
              <TableCell style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Tooltip title="Editar/ver">
                  <IconButton onClick={() => handleEdit(row)}>
                    <BorderColorIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton onClick={() => handleDelete(row)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    )
  }

  useEffect(() => {
    if (next != null) {
      // console.log("mostrar")
      getData(next)
    }
    setPaginado(false)

  }, [paginado])

  useEffect(() => {
    window.onscroll = () => {
      const scrollHeight = document.documentElement.scrollHeight
      const currentHeight =
        window.innerHeight + document.documentElement.scrollTop;
      if (currentHeight + 1 >= scrollHeight) {
        setPaginado(true)
      }

    };
  });


  return (
    <div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={result.severity} sx={{ width: '100%' }}>
            {result.text}
          </Alert>
        </Snackbar>
      </Stack>

      <Grid
        style={{ paddingBottom: "24px" }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            onChange={e => {
              let te = e.target.value
              console.log(te.length)
              if (te.length > 2) {
                resetAll()
                setText(te)
                // setText(te)
              } else if (te.length == 0) {
                setOrder("firstname")
                setText("")
                setData([])
                // resetAll()
              }


            }}
            className="input-search"
            id="outlined-basic"
            variant="outlined"
            placeholder="Search post..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <ButtonBio
            onClick={handleOpenAdd}
            variant="contained"
            disableRipple
            endIcon={<AddCircleOutlineIcon />} >
            Agregar usuario
          </ButtonBio>
        </Grid>
      </Grid>
      {data.length > 0 ?
        (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {showHeaders()}
              {showLista()}
            </Table>
          </TableContainer>
        ) : (
          <div>
            Cargando usuarios...
          </div>
        )
      }
      <Modal

        open={openAdd}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container alignItems="center"
            className="login-formulario">
            <Grid item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}
            >

              <Typography gutterBottom variant="h4" component="div">
                Crear nuevo usuario
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "-20px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={handleCloseAdd}>
                <CloseOutlinedIcon />
              </IconButton>
              {/* <Button onClick={handleClose}>x</Button> */}
            </Grid>
            <Divider />

            <Grid item>
              <form onSubmit={handleOnSubmit} className="login-formulario">
                <TextField
                  id="outlined-basic"
                  label="Nombres"
                  // variant="outlined"
                  required
                  value={values.firstname}
                  name="firstname"
                  type="text"
                  placeholder="Nombres..."
                  className="input-control"
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  label="Apellidos"
                  // variant="outlined"
                  value={values.lastname}
                  name="lastname"
                  type="text"
                  placeholder="Apellidos..."
                  className="input-control"
                  onChange={handleChange}
                />
                <TextField
                  required
                  id="outlined-basic"
                  label="Celular"
                  // variant="outlined"
                  name="phone"
                  type="number"
                  max= "2147483647"
                  min= "-2147483648"

                  placeholder="Celular..."
                  className="input-control"
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  label="Correo"
                  // variant="outlined"
                  required
                  name="email"
                  type="email"
                  placeholder="Email..."
                  className="input-control"
                  onChange={handleChange}
                />
                {/* <TextField
                  required
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  name="password"
                  placeholder="Contraseña..."
                  className="input-control"
                  onChange={handleChange}
                /> */}
                <OutlinedInput
                  fullWidth
                  required
                  placeholder="Ingrese su contraseña"
                  className="input-control"
                  // className="input-control"
                  type={valuesPass.showPassword ? 'text' : 'password'}
                  value={valuesPass.password}
                  onChange={handleChangePass('password')}
                  startAdornment={
                    <InputAdornment position="start">
                      <HttpsOutlinedIcon />
                    </InputAdornment>
                  } endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {valuesPass.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <LoadingButton
                  className="login-button"
                  size="large"
                  type="submit"
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                >
                  AGREGAR
                </LoadingButton>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container alignItems="center"
            className="login-formulario">
            <Grid item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}
            >

              <Typography gutterBottom variant="h4" component="div">
                Editar usuario
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "-20px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={handleCloseEdit}>
                <CloseOutlinedIcon />
              </IconButton>
              {/* <Button onClick={handleClose}>x</Button> */}
            </Grid>
            <Divider />

            <Grid item>
              <form onSubmit={handleOnSubmitEdit} className="login-formulario">
                <TextField
                  required
                  id="outlined-basic"
                  label="Nombres"
                  // variant="outlined"
                  name="firstname"
                  type="text"
                  placeholder="Nombres..."
                  className="input-control"
                  onChange={handleChangeEdit}
                  value={newUser.firstname}
                />
                <TextField
                  id="outlined-basic"
                  label="Apellidos"
                  // variant="outlined"
                  name="lastname"
                  type="text"
                  placeholder="Apellidos..."
                  className="input-control"
                  onChange={handleChangeEdit}
                  value={newUser.lastname}

                />
                <TextField
                  id="outlined-basic"
                  label="Celular"
                  // variant="outlined"
                  name="phone"
                  type="number"
                  placeholder="Celular..."
                  className="input-control"
                  onChange={handleChangeEdit}
                  value={newUser.phone}
                />

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={handleCloseEdit}
                    variant="outlined">CANCELAR</Button>
                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    ACEPTAR
                  </LoadingButton>
                </Grid>
              </form>
            </Grid>
            {/* <Grid item hidden={!registerResult.active}>
              <Alert severity={registerResult.severity}>{registerResult.text}</Alert>
            </Grid> */}
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container alignItems="center"
            className="login-formulario">
            <Grid item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}
            >

              <Typography gutterBottom variant="h4" component="div">
                Eliminar usuario
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "-20px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={handleCloseDelete}>
                <CloseOutlinedIcon />
              </IconButton>
              {/* <Button onClick={handleClose}>x</Button> */}
            </Grid>
            <Divider />

            <Grid item>
              <form onSubmit={handleOnSubmitDelete} className="login-formulario">
                <TextField
                  disabled
                  required
                  id="outlined-basic"
                  label="Nombres"
                  // variant="outlined"
                  name="firstname"
                  type="text"
                  placeholder="Nombres..."
                  className="input-control"
                  value={newUser.firstname}
                />
                <TextField
                  disabled
                  id="outlined-basic"
                  label="Apellidos"
                  // variant="outlined"
                  name="lastname"
                  type="text"
                  placeholder="Apellidos..."
                  className="input-control"
                  value={newUser.lastname}

                />
                <TextField
                  disabled
                  id="outlined-basic"
                  label="Celular"
                  // variant="outlined"
                  name="phone"
                  type="number"
                  placeholder="Celular..."
                  className="input-control"
                  value={newUser.phone}
                />

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={handleCloseDelete}
                    variant="outlined">CANCELAR</Button>
                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    disabled={canEliminated}
                  // disabled={true}
                  >
                    ACEPTAR
                  </LoadingButton>
                </Grid>
              </form>
            </Grid>

          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default User