// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// redux
import { Provider } from 'react-redux';
import store from './redux/configureStore'
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";
import { HashRouter} from 'react-router-dom';


// ------------
// ----------------------------------------------------------------------
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }
const root = ReactDOM.createRoot(document.getElementById('root'));
var hist = createBrowserHistory();
root.render(
  <Provider store={store} >
    <HashRouter history={hist}>
    <HelmetProvider >
      {/* <BrowserRouter> */}
        <App />
        {/* <Helmet>
          <script src={libreria}
            type="text/javascript" />
        </Helmet> */}
        {/* {AddLibrary(libreria)} */}
      {/* </BrowserRouter> */}
    </HelmetProvider>
    </HashRouter>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
