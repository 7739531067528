import axios from "axios"
let urlApi = "https://perunpdb.com.pe/api/molApp/userSearch/"
let urlReg = "https://perunpdb.com.pe/api/molApp/register/"
// const urlApi = "https://perunpdb.com.pe/api/molApp/userSearch/?ordering=email&search="

export const GetUsersSearch = (url) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.get(`${url}`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}


export const register = (user) => {
    const head = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(`${urlReg}`, user, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(null);
            });
    });
}

export const deleteUser = (token, id) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.delete(`https://perunpdb.com.pe/api/molApp/userp/${id}/`, head)
            .then(function ({ data }) {
                resolve(data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export const editUser = (token, user, id) => {
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        axios.put(`https://perunpdb.com.pe/api/molApp/userp/${id}/`, user, head)
            .then(function ({ data }) {
                resolve(data);
            })
            .catch(function (error) {
                console.log("error actions ", error)
                reject(error);
            });
    });
}

export const getDataUser =(token)=>{
    const head = {
        headers: {
            Authorization: 'Token ' + token, //the token is a variable which holds the token
            "Content-Type": "application/json"
        }
    }
    return new Promise((resolve, reject) => {
        // console.log(`${urlApi}${table}/${id}/`, head)
        axios.get(`https://perunpdb.com.pe/api/molApp/userbyToken/`, head)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}