import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import IMGPlantas from "../../assets/plantas.png"
import { ReactComponent as IconLogo } from "../../assets/perunpbd.svg"
const Informacion = ({ idioma }) => {
  return (
    <div style={{ backgroundColor: "#fff" }} name="about-us">
      <Grid container>
        <Grid item lg={4} md={4} xs={12} container justifyContent="center" alignItems="center" textAlign="center" minHeight="100%" > <img src={IMGPlantas} alt="plantas"
          className="info-img"
        />
        </Grid>
        <Grid item lg={8} md={8} xs={12} 
        className="info-container" justifyContent={"center"} alignItems={"center"} display="flex">
          <Container>
            {/* <IconLogo className="info-logo" /> */}
            <div className="info-title">PERUNPDB</div>
            <div className="info-contenido">
              
              <Typography className="info-contenido-text">
              {idioma.aboutName}
              </Typography>
              <p className="info-contenido-p">{idioma.aboutProject}</p>
              <Typography className="info-contenido-text">
                {idioma.aboutContenido}
              </Typography>
            </div>
          </Container>
        </Grid>
      </Grid>

    </div>

  )
}

export default Informacion