import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
// import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const account = JSON.parse(useSelector((store) => store.user.userInfo));

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const styleContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    background: "#C5C7D3",
    borderRadius: "4px",
    position: "relative",
    width: "64px",
    height: "64px",
    
  }
  const styleLetras = {
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "46px",
    display: "flex",
    alignItems: "flex-end",
    color: "#74818F",
  }
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <Box sx={{ px: 2.5, pb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {/* <Link underline="none" component={RouterLink} to="#"> */}
        {/* <AccountStyle> */}
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        <div style={styleContainer}>
          <div style={styleLetras}>
            {account?.firstname[0] + account?.lastname[0]}
          </div>
          <div style={{
            position: 'absolute',
            right: '-5px',
            bottom: '-5px',
            width: '16px',
            height: '16px',
            borderRadius:"100%",
            boxSizing: "border-box",
            background: "#4BAF4F",
            border: "2.5px solid #F0F2F5",
          }} />
        </div>

        <Box sx={{ pt: 2 }} style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {account?.firstname + " " + account?.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Administrador
          </Typography>
        </Box>
        {/* </AccountStyle> */}
        {/* </Link> */}
        <Divider />

      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box>  */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
