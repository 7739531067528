import React from 'react'
import { Container, Grid } from '@mui/material'
import ImageApp from "../../assets/app.png"

const AppMobil = ({ idioma }) => {
  return (
    <Grid container className="app-container" >
      <Grid item lg="6" md="6" xs="12" className="imagen">
        <img src={ImageApp} className="app-image" alt="mobil-perunpdb" />
      </Grid>
      <Grid item lg="6" md="6" xs="12">
        <Container>
          <div className="app-contenido">
            <div className="app-subtitle">{idioma.appMovil}</div>
            <div className="app-title"> {idioma.appMovilTitle}</div>
            <div className="app-description">{idioma.appMovilDescription}</div>
            <a href="https://play.google.com/store/apps/details?id=com.ucsm.perunpbd&pli=1" target="_blank" className="app-button" >
             {idioma.appDownload}
            </a>
          </div>
        </Container>
      </Grid>
    </Grid >

  )
}

export default AppMobil