import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: "eng", // spa por eng
}

export const languageReducer = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.value = action.payload;
        }

    },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageReducer.actions

export default languageReducer.reducer