import axios from "axios"
const urlApi = "https://perunpdb.com.pe/api/molApp/"

// userActions.js
export const userLogin = (obj) => {
    // console.log("obj", obj)
    return new Promise((resolve, reject) => {
        // console.log(urlApi + "login/")
        axios.post(urlApi + "login/", obj, {
            "Content-Type": "application/json"
        }).then((ress) => {
            resolve(ress);
        })
            .catch(function (error) {
                reject(error);
            });
    });
}