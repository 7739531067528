import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { getFilters } from 'src/actions/compuestosAction';
import "./css/filters.css"
import _ from "lodash"
import { useEffect } from 'react';
import { forEach } from 'lodash';
import { language } from "../language"
import { useSelector } from 'react-redux';

export default function NestedList({ checked, updateChecked }) {
  const lang = useSelector((state) => state.lang.value)
  // const [checked, setChecked] = React.useState(
  //   {
  //     class: [],
  //     genus: [],
  //     journal: [],
  //     kingdom: [],
  //     specie: [],
  //     year: []
  // }
  // );
  const [filters, setFilters] = React.useState({
    class: [],
    genus: [],
    journal: [],
    kingdom: [],
    specie: [],
    year: []
  });
  const [openCollapse, setOpenCollapse] = React.useState({
    class: false,
    genus: false,
    journal: false,
    kingdom: false,
    specie: false,
    year: false
  });

  const handleClick = (filtro) => {
    // console.log(openCollapse)
    // console.log(filtro, ": ", openCollapse[filtro])
    let option = openCollapse[filtro]
    setOpenCollapse({
      ...openCollapse,
      [filtro]: !option
    })
    //setOpen(!open);
  };
  const handleToggle = (key, value) => () => {
    const currentIndex = checked[key].indexOf(value);
    const newChecked = [...checked[key]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    updateChecked(key, newChecked);
    // setChecked({
    //   ...checked,
    //   [key]: newChecked
    // });
    // listaFilter(checked);
  };

  // useEffect(() => {
  //   console.log("checked", checked)
  // }, [checked])

  const getDataFiltros = () => {
    let filtros = getFiltersAsync();//traemos todos los filtros
    filtros.then(res => {
      // console.log("res", res.data.Filters)

      let unordered = res.data.Filters
      const ordered = Object.keys(unordered).sort().reduce(
        (obj, key) => {
          obj[key] = unordered[key].sort();
          return obj;
        },
        {}
      );
      setFilters(ordered);

    }).catch(error => {
      console.log("error ", error)
    })
  }

  const getFiltersAsync = async () => {
    let res = {};
    res = await getFilters()
    return res;
  }

  React.useEffect(() => {
    getDataFiltros();
  }, [])

  // React.useEffect(() => {
  //   colorearChips();

  // }, [allChips])

  // const colorearChips = () => {
  //   // console.log("allChips", allChips)
  //   let res = allChips.filter(ele => { if (ele.activo) return ele })
  //   let ch = checked
  //   res.forEach(item => {
  //     let currentIndex = -1
  //     let newChecked = []
  //     if (ch != null) {
  //       currentIndex = ch[item.key]?.indexOf(item.value);
  //       newChecked = [...ch[item.key]];
  //     }
  //     if (currentIndex === -1) {
  //       newChecked.push(item.value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }
  //     ch = {
  //       ...ch,
  //       [item.key]: newChecked
  //     }
  //   });
  //   console.log(ch)
  //   setChecked(ch)
  //   // listaFilter(ch);
  //   // listaFilter(ch);

  // }

  // const clickNew = (key, value) => {
  //   console.log("checked", checked)
  //   console.log(key, value)
  //   let currentIndex = -1
  //   let newChecked = []
  //   if (checked != null) {
  //     currentIndex = checked[key]?.indexOf(value);
  //     newChecked = [...checked[key]];
  //   }

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked({
  //     ...checked,
  //     [key]: newChecked
  //   });
  //   console.log(checked)
  // }


  const ShowFilters = () => {
    return (
      <div>
        {_.map(filters, (elements, key) => {
          return (
            <>
              <ListItemButton onClick={() => handleClick(key)}>
                <ListItemText primary={key.charAt(0).toUpperCase() + key.slice(1)} />
                {openCollapse[key] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapse[key]} timeout="auto" unmountOnExit>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {elements.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    //const texto ={value}.replace(/_/g, " ")
                    return (
                      <ListItem
                        key={value}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(key, value)}
                            checked={checked[key]?.indexOf(value) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText
                            style={{ "overflow-wrap": "break-word" }}
                            id={labelId}
                            primary={`${value}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </>
          )
        })}
      </div>
    )
  }
  return (
    <div>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader className="filters-title" component="div" id="nested-list-subheader">
            {language[lang].filters}
          </ListSubheader>
        }
      >
        {ShowFilters()}
        {/* 
        <ListItemButton onClick={handleClick}>

          <ListItemText primary="Inbox" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {[0, 1, 2, 3].map((value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(key,value)}
                      checked={checked.indexOf(value) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse> */}
      </List>

    </div >
  );
}
