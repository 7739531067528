import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import User2 from './pages/User2';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import BioPage from "./pages/BioPage"
import Compuesto from "./pages/Compuesto"
import CompuestosDash from "./pages/CompuestosDash"

// ----------------------------------------------------------------------

export default function Router() {

  return useRoutes([
    
    {
      exact:true,
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'compuestos', element: <CompuestosDash /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
      ],
    },
    {
      exact:true,
      path: 'login',
      element: <Login />,
    },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    {
      path: '404',
      element: <NotFound />
    },
    {
      exact:true,
      path: 'compuesto/:id',
      element: <Compuesto />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      exact:true,
      path: '/',
      element: <BioPage />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      exact:true,
      path: '/admin',
      element: <Navigate to="/dashboard/user" />
    },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '/', element: <Navigate to="/dashboard/app" /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      exact:true,
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
