import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import ImgLogin from "../assets/login-left.png"
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoBio } from '../assets/logo-login.svg';
import { ReactComponent as LogoBioWhite } from '../assets/logo-login-white.svg';
import useMediaQuery from '@mui/material/useMediaQuery';


// ----------------------------------------------------------------------
import "./css/login.css"


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Button onClick={() => navigate("/")}>
            {
              matches && mdUp? (
                <LogoBioWhite className="login-logo" />
                ) : (
                <LogoBio className="login-logo" />
              )
            }
          </Button>
          {/* <Logo /> */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle className="login-container-img">
            <img src={ImgLogin} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {/* <Typography variant="h4" gutterBottom>
              Sign in to Minimal
            </Typography> */}

            <LoginForm />

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
