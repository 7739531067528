import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link, Stack, Snackbar, IconButton, InputAdornment, FormControl, FormLabel, FormGroup, Button, Container, Grid,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { userLogin } from 'src/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./login.css"
// reducers
import { activeLoading, inactiveLoading, logout, saveToken, saveExpiry, saveUser } from 'src/redux/slices/userReducer';
import LogoTitle from '../../../assets/logo-title.svg'
import { getDataUser } from "../../../actions/userAction"

// ----------------------------------------------------------------------
export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, userToken, expiry } = useSelector((state) => state.user)
  const [result, setResult] = useState({
    severity: "info",
    text: "",
  })
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    username: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false);

  const loginAsync = async (obj) => {
    let res = {}
    res = await userLogin(obj)
    return res;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(activeLoading());
    // console.log("user", values.username)
    // console.log("pass", values.password)
    let data =
    {
      "username": values.username,
      "password": values.password
    }
    // console.log("---data", data)

    let reponse = loginAsync(data)
    reponse.then(res => {
      dispatch(saveExpiry(res.data.expiry))
      dispatch(saveToken(res.data.token))
      dispatch(inactiveLoading());
      let resUser = getDataUserAsync(res.data.token)
      resUser.then(user => {
        console.log("esta ingresando ", user.data)
                // console.log(user.data)
        dispatch(saveUser(user.data))
        setResult({
          text: "Bienvenido " + user.data.firstName,
          severity: "success"
        })
        handleOpen();
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      // console.log("error", error)
      setResult({
        text: "Unable to log in with provided credentials.",
        severity: "error"
      })
      handleOpen();
      dispatch(inactiveLoading());
    })
    closeAviso()
  }
  const closeAviso = () => {
    const timer = setTimeout(() => {
      setResult({
        text: "",
        severity: ""
      })
      handleClose();
    }, 6000);
    return () => clearTimeout(timer);
  }
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (userToken != null) {
      navigate("/admin")
    }
  }, [userToken])
  const getDataUserAsync = async (token) => {
    let res = {}
    res = await getDataUser(token)
    return res;
  }



  return (
    <>
      {userToken != null ?
        (<>Loading</>) : (
          <>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={result.severity} sx={{ width: '100%' }}>
                  {result.text}
                </Alert>
              </Snackbar>
            </Stack>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                {/* <img src={LogoTitle} className="login-logo" alt="logo-perunbd" /> */}
                <h1 className="footer-perunpdb">
                    <span className="footer-perunpdb-first">
                      PERU
                    </span>
                    <span className="footer-perunpdb-second">
                      NPDB
                    </span>
                  </h1>
              
              </Grid>
              <Grid item className="login-subtitle">
                Peruvian natural products database
              </Grid>
              <div>
                <form onSubmit={handleOnSubmit} className="login-formulario">
                  <FormControl variant="outlined">
                    <FormLabel>Usuario</FormLabel>
                    <OutlinedInput
                      value={values.username}
                      onChange={handleChange('username')}
                      required
                      type='text'
                      // type='email'
                      className="input-control"
                      placeholder="Ingrese su usuario"
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonOutlineIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl variant="outlined">
                    <FormLabel className="input-label">Contraseña</FormLabel>
                    <OutlinedInput
                      required
                      placeholder="Ingrese su contraseña"
                      className="input-control"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                      startAdornment={
                        <InputAdornment position="start">
                          <HttpsOutlinedIcon />
                        </InputAdornment>
                      } endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    INGRESAR
                  </LoadingButton>
                  <a href="https://perunpdb.com.pe/api/molApp/password_reset" className="forget-pass">¿Olvidaste tu contraseña?</a>
                </form>
              </div>
            </Grid>
          </>
        )
      }

    </>

    // <FormProvider methods={methods} onSubmit={handleOnSubmit}>
    //   <Stack spacing={3}>
    //     <RHFTextField name="email" label="Email address" />

    //     <RHFTextField
    //       name="password"
    //       label="Password"
    //       type={showPassword ? 'text' : 'password'}
    //       InputProps={{
    //         endAdornment: (
    //           <InputAdornment position="end">
    //             <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
    //               <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
    //             </IconButton>
    //           </InputAdornment>
    //         ),
    //       }}
    //     />
    //   </Stack>

    //   <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
    //     <RHFCheckbox name="remember" label="Remember me" />
    //     {/* <Link variant="subtitle2" underline="hover">
    //       Forgot password?
    //     </Link> */}
    //   </Stack>

    //   <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
    //     Login
    //   </LoadingButton>
    // </FormProvider>
  );
}
