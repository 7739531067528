import React, { useEffect, useState } from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import "./css/user.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ButtonBio } from 'src/components/ButtonBio';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { LoadingButton } from '@mui/lab';
import {
  getCompuestosSearch,
  getCSV,
  editCompuesto,
  addCompuesto,
  deleteCompuesto,
  getCompuestosFilters,
  uploadCSVCompuestos
} from "../actions/compuestosAction"
import TemplateExcel from "../assets/templateExcel.csv"
import { CSVLink, CSVDownload } from "react-csv";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Input,
  FormControl,
  FormLabel,
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Modal,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  OutlinedInput,
  Divider,
  Alert,
  Container,
  MenuItem,
  Menu,
  Tooltip,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { map, set } from 'lodash';
import { useSelector } from 'react-redux';
const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // // width: "70%",
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100%',
  position: 'fixed',
  top: '50%',
  left: '50%',
  // transform: 'translate(0, -50%)',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto'
};



const headers = [

  {
    key: "id_mol",
    name: "ID Mol"
  },
  {
    key: "namemol",
    name: "Nombre"
  },
  {
    key: "kingdommol",
    name: "Reino"
  },
  {
    key: "genusmol",
    name: "Género"
  }
]

let initFilter = {
  "Search": "",
  "Orderby": "id_mol",
  "Filters": {
    classmol: [],
    genusmol: [],
    journalmol: [],
    kingdommol: [],
    speciemol: [],
    yearrefmol: []
  }
}
const initRegister = {
  "id_mol": "",
  "namemol": "",
  "referencemol": "",
  "yearrefmol": 0,
  "kingdommol": "",
  "genusmol": "",
  "speciemol": "",
  "smilemol": "",
  "doimol": "",
  "journalmol": "",
  "classmol": "",
  "statemol": 1,
}
const CompuestosDash = () => {
  const token = useSelector((store) => store.user.userToken);
  const [csvData, setCsvData] = useState([])
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [text, setText] = useState("")
  const [order, setOrder] = useState("id_mol")
  const [paginado, setPaginado] = useState(false)
  const [next, setNext] = useState(null);
  const [register, setRegister] = useState(initRegister)
  const [filtros, setFiltros] = useState(initFilter)

  // modal create
  const [open, setOpen] = useState(false);
  const [modalExcel, setModalExcel] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [filecsv, setFilecsv] = useState(null);
  const [result, setResult] = useState({
    severity: "info",
    text: "",
  })
  const openAnchor = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    // setRegisterResult({
    //   severity: "info",
    //   text: "",
    //   active: false
    // })
    setOpen(true)
  };
  const openModalExcel = () => { setModalExcel(true) }
  const openModalAdd = () => { setModalAdd(true) }
  const openModalEdit = () => { setModalEdit(true) }
  const openModalDelete = () => { setModalDelete(true) }
  const closeModalExcel = () => { setModalExcel(false) }
  const closeModalAdd = () => { setModalAdd(false) }
  const closeModalEdit = () => { setModalEdit(false) }
  const closeModalDelete = () => { setModalDelete(false) }
  const getDataFiltersAsync = async (link) => {
    let res = []
    res = await getCompuestosFilters(link, filtros)
    return res
  }
  const getDataSearchAsync = async (link) => {
    let res = []
    res = await getDataFiltersAsync(link)
    return res
  }
  const getData = (link) => {
    // console.log("compuesto", link)
    let response = getDataSearchAsync(link)
    response.then(res => {
      UpdateStatesMore(res.data)
    }).catch(error => {
      console.log("error")
    })
  }
  const getDataAction = (link) => {
    let response = getDataSearchAsync(link)
    response.then(res => {
      setCount(res.data.count)
      setNext(res.data.next)
      setData([...res.data.results])
      setPaginado(false)
    }).catch(error => {
      console.log("error")
    })
  }
  const UpdateStates = (response) => {
    setCount(response.count)
    setNext(response.next)
    setData(response.results)
  }
  const UpdateStatesMore = (response) => {
    setCount(response.count)
    setNext(response.next)
    setData([...data, ...response.results])
  }
  useEffect(() => {
    let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
    getData(link)
  }, [])
  const resetAll = () => {
    setData([])
    setCount(0)
    setNext(null)
  }
  useEffect(() => {
    let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
    getData(link)
  }, [filtros])
  // console.log("---filtros",filtros)
  const showHeaders = () => {
    return (
      <>
        <TableHead style={{ backgroundColor: "#F0F2F5" }}>
          <TableRow>
            {
              headers.map((item, id) => {
                return (
                  <TableCell key={id}>
                    <Button className='user-btn-filter'
                      onClick={() => {
                        if (item.key != order) {
                          resetAll()
                          setFiltros({
                            ...filtros,
                            "Search": text,
                            "Orderby": item.key,
                          })
                          // setOrder(item.key)
                        }
                      }}
                      endIcon={<UnfoldMoreIcon />}>{item.name}</Button>
                  </TableCell>
                )
              })
            }
            <TableCell style={{
              "font-weight": "700",
              "line-height": "1.7142857142857142",
              "font-size": "0.875rem",
              color: "#74818F"
            }} align="center">Acción</TableCell>
          </TableRow>
        </TableHead>
      </>
    )
  }
  const showLista = () => {
    return (
      <>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              style={{
                borderBottom: "2.5px solid #f0f2f5"
              }}
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id_mol}</TableCell>
              <TableCell >{row.namemol}</TableCell>
              <TableCell >{row.kingdommol}</TableCell>
              <TableCell >{row.genusmol}</TableCell>
              <TableCell style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <IconButton onClick={() => handleEdit(row)}>
                  <BorderColorIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(row)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    )
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log("next", next)
    if (next != null) {
      console.log("next", next)
      getData(next)
    }
    setPaginado(false)

  }, [paginado])

  useEffect(() => {
    window.onscroll = () => {
      const scrollHeight = document.documentElement.scrollHeight
      const currentHeight =
        window.innerHeight + document.documentElement.scrollTop;
      if (currentHeight + 1 >= scrollHeight) {
        console.log("ingresa", paginado)
        setPaginado(true)
      }

    };
  });


  // agregar compuesto
  const addCompuestoAsync = async () => {
    let res = null
    res = await addCompuesto(token, register)
    return res;
  }
  const handleChange = (event) => {
    let { name, value } = event.target
    setRegister({ ...register, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // console.log("onSubmit", register);

    let response = addCompuestoAsync()
    response.then(res => {
      // resetAll()
      // handleClose()
      setModalAdd(false)
      setRegister(initRegister)
      setResult({
        text: "Se agrego el compuesto exitosamente",
        severity: "success"
      })
      handleOpen()
      // let link = (`https://perunpdb.com.pe/api/molApp/userSearch/?ordering=${order}&page=1&search=${text}`)
      // getDataAction(link)

      let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
      getDataAction(link)
    }).catch(error => {
      console.log("error", error)
      setModalAdd(false)
      setResult({
        text: "No se pudo agregar el compuesto",
        severity: "error"
      })
      handleOpen();

    })
  }
  //   Cerrar sesión
  // Cambiar contraseña
  // Editar información
  // editar
  const editAsync = async () => {
    let res = null;
    res = await editCompuesto(token, register, register.id)
    return res;
  }
  // const handleChangeEdit = (event) => {
  //   let { name, value } = event.target
  //   setRegister({ ...register, [name]: value });
  // };
  const handleEdit = (dataUser) => {
    // console.log(dataUser)
    setRegister(dataUser)
    setModalEdit(true)
  }
  const handleOnSubmitEdit = (e) => {
    e.preventDefault();
    let response = editAsync()
    response.then((res) => {
      // resetAll()
      // mensaje de eliminado 
      setModalEdit(false)
      setRegister(initRegister)
      setResult({
        text: "Se modifico exitosamente",
        severity: "success"
      })
      handleOpen();
      // let link = (`https://perunpdb.com.pe/api/molApp/sea/?ordering=${order}&page=1&search=${text}`)
      // getDataAction(link)
      let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
      getDataAction(link)
    }).catch(err => {
      console.log("no se puede editar", err)
      // mensaje de error
      setModalEdit(false)
      setRegister(initRegister)
      setResult({
        text: "No se pudo modificar",
        severity: "error"
      })
      handleOpen();

    })
  }

  // delete
  const handleDelete = (dataUser) => {
    setRegister(dataUser)
    setModalDelete(true)
  }
  const deleteAsync = async (id) => {
    let res = null;
    res = await deleteCompuesto(token, id)
    return res;
  }
  const uploadCSVCompuestosAsync = async () => {
    let res = null;
    res = uploadCSVCompuestos(token, filecsv);
    return res;
  }

  const handleOnSubmitUpload = (e) => {
    e.preventDefault();
    let response = uploadCSVCompuestosAsync()
    response.then(res => {
      setModalExcel(false)
      setFilecsv(null)
      setResult({
        text: "Se subió exitosamente el archivo",
        severity: "success"
      })
      handleOpen()
      let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
      getDataAction(link)
      // console.log(res)
    }).catch(err => {
      setModalExcel(false)
      setFilecsv(null)
      setResult({
        text: "No se subió el archivo",
        severity: "error"
      })
      handleOpen();
      console.log(err)
    })
  }
  const handleOnSubmitDelete = (e) => {
    e.preventDefault();
    // console.log("eliminadno", register.id)
    let response = deleteAsync(register.id)
    response.then((res) => {
      console.log(res)
      // mensaje de eliminado 
      setModalDelete(false)
      setRegister(initRegister)
      setResult({
        text: "Se elimino exitosamente",
        severity: "success"
      })
      handleOpen();
      // let link = (`https://perunpdb.com.pe/api/molApp/sea/?ordering=${order}&page=1&search=${text}`)
      // getDataAction(link)
      // resetAll()
      let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
      getDataAction(link)
    }).catch(err => {
      console.log("error en eliminar", err)
      // mensaje de error
      setModalDelete(false)
      setRegister(initRegister)
      setResult({
        text: "No se pudo eliminar",
        severity: "error"
      })
      handleOpen();
    })
  }

  const getCSVAsync = async () => {
    let data = null
    data = await getCSV();
    return data;
  }
  const handleDownload = () => {
    let response = getCSVAsync();
    response.then(res => {
      // console.log(res.data.split("\n"))
      let r = res.data.split("\n")
      let csv = map(r, item => [item])
      // console.log("---", csv);
      let array = []
      array.push(csv)
      setCsvData(array)
    }).catch(error => {
      console.log("error", error)
    })
    // handleCloseAnchor()
  }
  useEffect(() => {
    handleDownload()
  }, [])
  const modalAddCompuesto = () => {
    openModalAdd()
    handleCloseAnchor()
  }
  const modalUploadExcelCompuesto = () => {
    openModalExcel()
    handleCloseAnchor()
  }
  const handleChangeFile = (e) => {
    console.log("e.target.files", e.target.files[0])
    if (e.target.files[0] != null || e.target.files[0] != undefined) {
      setFilecsv(e.target.files[0]);
    }
  }
  return (
    <div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={result.severity} sx={{ width: '100%' }}>
            {result.text}
          </Alert>
        </Snackbar>
      </Stack>

      <Grid
        style={{ paddingBottom: "24px" }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            onChange={e => {
              let te = e.target.value
              if (te.length > 2) {
                resetAll()
                //setText(te)
                setFiltros({
                  ...filtros,
                  "Search": te,
                })
              } else if (te.length == 0) {
                //setOrder("id_mol")
                //setText("")
                setFiltros({
                  ...filtros,
                  "Search": "",
                  "Orderby": "id_mol",
                })
                setData([])
              }
            }}
            className="input-search"
            id="outlined-basic"
            variant="outlined"
            placeholder="Search post..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <ButtonBio
            id="basic-button"
            aria-controls={openAnchor ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAnchor ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            disableRipple
            endIcon={<AddCircleOutlineIcon />}
          >
            Agregar compuesto
          </ButtonBio>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleCloseAnchor}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleCloseAnchor}>
              <CSVLink data={csvData} className="link-csv">
                Descargar Formato Masivo (.csv)

              </CSVLink>
            </MenuItem>
            <MenuItem onClick={modalUploadExcelCompuesto}>Importar Masivo (.csv)</MenuItem>
            <MenuItem onClick={modalAddCompuesto}>Agregar Compuesto</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {data.length > 0 ?
        (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {showHeaders()}
              {showLista()}
            </Table>
          </TableContainer>
        ) : (
          <div>
            Cargando compuestos
          </div>
        )
      }
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={modalAdd}
      //onClose={closeModalAdd}
      >
        <Box sx={style}
          className="user-modal-add">
          <Container>
            <Grid container direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}>
              <Typography gutterBottom variant="h4" component="div">
                Agregar nuevo compuesto
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={closeModalAdd}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
            <Divider />

            <form onSubmit={handleOnSubmit} className="">
              <Grid container mb={2}>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="ID Mol"
                    // variant="outlined"
                    name="id_mol"
                    type="text"
                    placeholder="PeruNPDB_0..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Year"
                    // variant="outlined"
                    name="yearrefmol"
                    type="number"
                    placeholder="20..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Name"
                    // variant="outlined"
                    name="namemol"
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Reference"
                    // variant="outlined"
                    name="referencemol"
                    type="text"
                    placeholder="10.1016/x.xxx.xxx..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>

                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Kingdom"
                    // variant="outlined"
                    name="kingdommol"
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Specie"
                    // variant="outlined"
                    name="speciemol"
                    type="text"
                    placeholder="Specie..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Genus"
                    // variant="outlined"
                    name="genusmol"
                    type="text"
                    placeholder="Genus..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>

                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="DOI"
                    // variant="outlined"
                    name="doimol"
                    type="text"
                    placeholder="DOI..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="12" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Smile"
                    // variant="outlined"
                    name="smilemol"
                    type="text"
                    placeholder="CCCC=O..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Journal"
                    // variant="outlined"
                    name="journalmol"
                    type="text"
                    placeholder="Journal..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>

                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Class"
                    // variant="outlined"
                    name="classmol"
                    type="text"
                    placeholder="Class..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>

              </Grid>
              <Divider />
              <Grid
                mt={2}
                mb={2}
                container
              >
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={closeModalAdd}
                    variant="outlined">CANCELAR</Button>
                </Grid>
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    AGREGAR
                  </LoadingButton>
                </Grid>
              </Grid>

            </form>
          </Container>
        </Box>
      </Modal>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={modalEdit}
      //onClose={closeModalEdit}
      >
        <Box sx={style}
          className="user-modal-add">
          <Container>
            <Grid container direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}>
              <Typography gutterBottom variant="h4" component="div">
                Editar  compuesto
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={closeModalEdit}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
            <Divider />

            <form onSubmit={handleOnSubmitEdit} className="">
              <Grid container mb={2}>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="ID Mol"
                    // variant="outlined"
                    name="id_mol"
                    value={register.id_mol}
                    type="text"
                    placeholder="PeruNPDB_0..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Year"
                    // variant="outlined"
                    name="yearrefmol"
                    value={register.yearrefmol}
                    type="number"
                    placeholder="20..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Name"
                    // variant="outlined"
                    name="namemol"
                    value={register.namemol}
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Reference"
                    // variant="outlined"
                    name="referencemol"
                    value={register.referencemol}
                    type="text"
                    placeholder="10.1016/x.xxx.xxx..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>

                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Kingdom"
                    // variant="outlined"
                    name="kingdommol"
                    value={register.kingdommol}
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Specie"
                    // variant="outlined"
                    name="speciemol"
                    value={register.speciemol}
                    type="text"
                    placeholder="Specie..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Genus"
                    // variant="outlined"
                    name="genusmol"
                    value={register.genusmol}
                    type="text"
                    placeholder="Genus..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>

                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="DOI"
                    // variant="outlined"
                    name="doimol"
                    value={register.doimol}
                    type="text"
                    placeholder="DOI..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="12" p={1}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Smile"
                    // variant="outlined"
                    name="smilemol"
                    value={register.smilemol}
                    type="text"
                    placeholder="CCCC=O..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Journal"
                    // variant="outlined"
                    name="journalmol"
                    value={register.journalmol}
                    type="text"
                    placeholder="Journal..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>

                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Class"
                    // variant="outlined"
                    name="classmol"
                    value={register.classmol}
                    type="text"
                    placeholder="Class..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>

              </Grid>
              <Divider />
              <Grid
                mt={2}
                mb={2}
                container
              >
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={closeModalEdit}
                    variant="outlined">CANCELAR</Button>
                </Grid>
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    ACEPTAR
                  </LoadingButton>
                </Grid>
              </Grid>

            </form>
          </Container>
        </Box>
      </Modal>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={modalDelete}
      //onClose={closeModalDelete}
      >
        <Box sx={style}
          className="user-modal-add">
          <Container>
            <Grid container direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}>
              <Typography gutterBottom variant="h4" component="div">
                Eliminar compuesto
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={closeModalDelete}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
            <Divider />

            <form onSubmit={(e) => handleOnSubmitDelete(e)} className="">
              <Grid container mb={2}>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-basic"
                    label="ID Mol"
                    // variant="outlined"
                    name="id_mol"
                    value={register.id_mol}
                    type="text"
                    placeholder="PeruNPDB_0..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Name"
                    value={register.namemol}
                    // variant="outlined"
                    name="namemol"
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Kingdom"
                    // variant="outlined"
                    name="kingdommol"
                    value={register.kingdommol}
                    type="text"
                    placeholder="Compound Name..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Specie"
                    value={register.speciemol}
                    // variant="outlined"
                    name="speciemol"
                    type="text"
                    placeholder="Specie..."
                    // className="input-control"
                    onChange={handleChange}
                  />

                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Genus"
                    value={register.genusmol}
                    // variant="outlined"
                    name="genusmol"
                    type="text"
                    placeholder="Genus..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs="12" md="6" p={1}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Class"
                    // variant="outlined"
                    name="classmol"
                    type="text"
                    value={register.classmol}
                    placeholder="Class..."
                    // className="input-control"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid
                mt={2}
                mb={2}
                container
              >
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >
                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={closeModalDelete}
                    variant="outlined">CANCELAR</Button>
                </Grid>
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    ACEPTAR
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={modalExcel}
      //onClose={closeModalDelete}
      >
        <Box sx={style}
          className="user-modal-add">
          <Container>
            <Grid container direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"relative"}>
              <Typography gutterBottom variant="h4" component="div">
                Subir Excel
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
                size="small"
                aria-label="close" onClick={closeModalExcel}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
            <Divider />

            <form onSubmit={(e) => handleOnSubmitUpload(e)} className="">
              <Grid container mb={3} mt={3}>
                <Grid item xs="12" md="6" p={1} justifyContent="center" align="center">
                  <a href={TemplateExcel} download style={{ color: "#000000" }}>
                    <Button variant="outlined" startIcon={<FileDownloadIcon />}>
                      Download template
                    </Button>
                  </a>
                </Grid>

                <Grid item xs="12" md="6" p={1} justifyContent="center" align="center" >
                  {/* <Button type="file" variant="contained" endIcon={<SendIcon />}>
                    Upload CSV
                  </Button> */}

                  <TextField
                    required
                    name="filecsv"
                    placeholder="Upload CSV"
                    type="file"
                    id="input-with-icon-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SendIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    onChange={handleChangeFile}
                  />

                </Grid>
              </Grid>
              <Divider />
              <Grid
                mt={2}
                mb={2}
                container
              >
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >
                  <Button
                    style={{
                      borderColor: "#A8B8C7",
                      color: "#A8B8C7",
                      width: "130px",
                      height: "48px",
                    }}
                    onClick={closeModalExcel}
                    variant="outlined">CANCELAR</Button>
                </Grid>
                <Grid item justifyContent={"center"} alignitems="center" sm="6" >

                  <LoadingButton
                    className="login-button"
                    size="large"
                    type="submit"
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    ACEPTAR
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>

    </div>
  )
}

export default CompuestosDash;