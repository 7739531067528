import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NavBarMain from 'src/components/NavBar/NavBarMain'
import BioLanding from "./BioLanding"
import Footer from './Footer'
import Portada from './Portada'
import SearchMol from './SearchMol'
import { language } from "../language"
import { Card, Grid } from '@mui/material'
import Filter from './Filter'
import SearchMolItem from './SearchMolItem'
import { getCompuestosPage, getFilters } from 'src/actions/compuestosAction'
import { getCompuestosFilters } from "../actions/compuestosAction"
import { Container, TextField, TablePagination } from '@mui/material'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import _ from 'lodash'
import { random } from 'lodash'
import "./css/chip.css"
import Prueba from './Prueba'
import SmilePicture from './SmilePicture'
import { Jsme } from 'jsme-react'

let initFilter = {
  "Search": "",
  "Orderby": "id_mol",
  "Filters": {
    classmol: [],
    genusmol: [],
    journalmol: [],
    kingdommol: [],
    speciemol: [],
    yearrefmol: []
  }
}
const BioPage = () => {
  const lang = useSelector((state) => state.lang.value)
  const [textSearch, setTextSearch] = useState("")
  const [optionSearch, setOptionSearch] = useState(0)
  const [data, setData] = useState([])
  const [offset, setOffset] = useState(0)
  const [isFetching, setIsFetching] = useState(false);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)
  const [paginado, setPaginado] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [chips, setChips] = useState([]);
  const [filtros, setFiltros] = useState(initFilter)
  const [checked, setChecked] = React.useState(
    {
      class: [],
      genus: [],
      journal: [],
      kingdom: [],
      specie: [],
      year: []
    }
  );
  const [isSearch, setIsSearch] = useState(false)
  const changeOption = (op) => {
    setOptionSearch(op)
    setChecked(
      {
        class: [],
        genus: [],
        journal: [],
        kingdom: [],
        specie: [],
        year: []
      }
    )
    setFiltros(initFilter)
    // console.log("optionSearch", optionSearch)//0 1 2
  }
  const changeText = (txt) => {
    setTextSearch(txt)
    // console.log("textSearch", textSearch)
    setFiltros({
      ...filtros,
      "Search": txt
    })

  }
  const getDataFiltersAsync = async (link) => {
    let res = []
    res = await getCompuestosFilters(link, filtros)
    return res
  }
  const getData = (link) => {
    let response = getDataFiltersAsync(link)
    response.then(res => {
      // console.log(res.data)
      UpdateStatesMore(res.data)
      if (res.data.count > 10 && initFilter != filtros)
        almacenar(link)
    }).catch(error => {
      console.log("error", error)
    })
  }
  const UpdateStatesMore = (response) => {
    setCount(response.count)
    setNext(response.next)
    //setData([...data, ...response.results])
    setData([...response.results])

  }

  useEffect(() => {
    let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
    getData(link)
  }, [])

  const almacenar = (link) => {
    localStorage.setItem("link", JSON.stringify(link));
    localStorage.setItem("filtro", JSON.stringify(filtros));
  }

  useEffect(() => {
    let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=${page}`)
    getData(link)
  }, [page])

  const getListaFilter = () => {
    // console.log("getListaFilter", checked)
    let lista = _.map(checked, (val, key) => {
      let k = ""
      if (key === "year") k = "yearrefmol"
      else k = key + "mol"
      return { "key": [k], "value": val }
      // newList = { ...newList, [k]: val }
    })
    let newList = lista.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    // console.log("newList", newList)
    setFiltros({
      ...filtros,
      "Filters": newList
    })
    // console.log("filtros", filtros)
    // let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
    // getData(link)
    setIsSearch(!isSearch)
  }
  // useEffect(() => {
  //   colorearChips()
  // }, [filtros])
  // const colorearChips = () => {
  //   let fil = filtros.Filters
  //   // console.log("----fil",fil)
  //   let res = chips.map(ele => {

  //     let existe = fil[ele.key + "mol"]?.indexOf(ele.value);
  //     if (existe === -1) {
  //       return { ...ele, activo: false }
  //     } else {
  //       return { ...ele, activo: true }
  //     }
  //   })
  //   setChips(res)
  // }
  useEffect(() => {
    // console.log("Buscando")
    let link = (`https://perunpdb.com.pe/api/molApp/sea/?page=1`)
    getData(link)

    //console.log("ingresando")
  }, [isSearch])
  const resetAll = () => {
    setData([])
    setCount(0)
    setNext(null)
    setPage(1)
    setRowsPerPage(0)
  }
  const changeBuscar = (e) => {
    e.preventDefault();
    resetAll()
    setIsSearch(!isSearch)
  }
  const handleChangePage = (event, newPage) => {
    // console.log(newPage + 1);
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick = (item) => {
    let res = chips.map(ele => {
      if (ele.value == item.value) return { ...ele, "activo": !item.activo }
      return ele
    })
    setChips(res)
    // console.log("--item", item)
    // if (res.find(ele => ele.value == item.value).activo)
    handleChipsFilter(item.key, item.value)
  };
  const updateFilterChips = () => {
    let res = chips.map(ele => {
      let existe = checked[ele.key].indexOf(ele.value)
      if (existe === -1)
        return { ...ele, "activo": false }
      else return { ...ele, "activo": true }
    })
    setChips(res)

  }

  const handleChipsFilter = (key, value) => {
    const currentIndex = checked[key].indexOf(value);
    const newChecked = [...checked[key]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked({
      ...checked,
      [key]: newChecked
    });
  }
  const generateChips = () => {
    let filtros = getFiltersAsync();
    filtros.then(res => {
      // createLista(res.data.Filters)
      // console.log(res.data.Filters)
      let lista = _.map((res.data.Filters), (value, key) => {
        return value.map(ele => { if (ele.length <= 13) return { "value": ele, "key": key, "activo": false } })
      })

      let results = lista.flat(1)
      results = results.filter(element => {
        return element !== undefined;
      });
      let f = []
      for (let i = 0; i < 20; i++) {
        let item = results[Math.floor(Math.random() * results.length)]

        let existe = f.find(ele => ele.value == item.value)
        if (!existe) {
          f.push(item)
        }

      }
      setChips(f)

    }).catch(error => {
      console.error(error)
    })
  }
  const getFiltersAsync = async () => {
    let res = {};
    res = await getFilters()
    return res;
  }
  useEffect(() => {
    generateChips();
  }, [])

  useEffect(() => {
    // console.log("updateFilterChips")
    updateFilterChips()
    getListaFilter()

    // console.log(checked)
  }, [checked])

  const updateChecked = (k, checks) => {
    // console.log(checks)
    setChecked({
      ...checked,
      [k]: checks
    });
  }
  // console.log(chips)
  return (
    <div>
      <NavBarMain />
      <Portada
        searchText={changeText}
        optionSelect={changeOption}
        buscar={changeBuscar}
      />
      {/* <Prueba
        smile="CCCCCCCC(=O)O"
      />
      <SmilePicture
        smile="CCCCCCCC(=O)O"
        
        /> */}

      {/* <Grid container>
        {
          ["CCCCCCCC(=O)O", "CCCC=O", "CCCCCCCC(=O)O"].map(ele => {
            return (
              <Grid item xs="8" lg="4">
                <div className="recientes-mol-card">
                  <Container>
                    <SmilePicture
                      smile={ele}
                      width={"100%"}
                      height={"255px"}
                    />
                  </Container>
                </div>
              </Grid>
            )
          })
        }
      </Grid> */}

      {
        optionSearch == 0 ? (
          <BioLanding texto={textSearch} chips={chips} />
        ) : (
          <div style= {{paddingBottom: "30px"}}>
            <Container className="container-sugerencias">
              <div className="chips-container">
                <div >{language[lang].suggestions}</div>
                <Grid container spacing={1} className="chips-container-items">
                  <Stack direction="row" spacing={2}>
                    {chips.map(ele => {
                      return <Chip className={ele.activo ? "mychip-active" : "mychip"} label={ele.value} variant="outlined" onClick={() => {
                        handleClick(ele)
                      }} />
                    })}
                    {/* <Chip label="Clickable" onClick={handleClick} /> */}
                  </Stack>
                </Grid>
              </div>
            </Container>

            <Grid container style= {{paddingTop: '0px'}}>
              {/* <Grid item xs="12" justifyContent="flex-end"
                alignItems="center">
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={count}
                  rowsPerPage={10}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid> */}
              <Grid item xs="4" style={{background: '#fff'}}>
                <Filter
                  checked={checked}
                  updateChecked={updateChecked}
                />
              </Grid>
              <Grid item xs="8" className="container-result-item" >
                {/* {data && data.map(item => {
                  return (<div style={{ padding: "10px" }}>
                    <SearchMolItem mol={item} />
                  </div>
                  )
                })} */}
 <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={count}
                  rowsPerPage={10}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {
                  
                  data.length > 0 ? (
                    data.map(item => {
                      return (<div style={{paddingBottom: "10px" }}>
                        <SearchMolItem mol={item} />
                      </div>
                      )
                    })) : (<div className="search-0">
                      {language[lang].dontResults}
                    </div>)
                }
              </Grid>
            </Grid>
          </div>
        )
      }
      <Footer />

    </div >
  )
}

export default BioPage