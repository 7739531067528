import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReactComponent as ImageLogo } from '../../assets/logoNav.svg'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import { useNavigate } from "react-router-dom";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Link } from "react-scroll"
import "./mynav.css"
import { Grid, Menu, MenuItem, NativeSelect, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from "../../redux/slices/languageReducer"
import Fade from '@mui/material/Fade';
import LanguagePopover from 'src/layouts/dashboard/LanguagePopover';
import { language } from '../../language/index';


const drawerWidth = 240;

function NavBarMain(props) {
  const lang = useSelector((state) => state.lang.value)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const navItems = [language[lang].usNav, language[lang].team,language[lang].contactNav];
  const navItems = [
    {
      path: "about-us",
      name: language[lang].usNav
    },
    {
      path: "team",
      name: language[lang].team
    },
    {
      path: "contact",
      name: language[lang].contactNav
    }];
  const mytheme = createTheme({
    palette: {
      primary: {
        main: '#fff',
      },

    },
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleChangeLang = (e) => {
    dispatch(changeLanguage(e));
    handleClose();
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography sx={{ my: 2 }} noWrap>
        <ImageLogo className="nav-logo" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to={item.path} smooth={true} duration={500}>
                <ListItemText primary={item.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem key="lang" disablePadding>
        <ListItemButton sx={{ textAlign: 'center' }}>
          <LanguagePopover />
          {/* <Button
            endIcon={<ExpandMoreOutlinedIcon />}
            className="nav-btn"
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}

          >
            {lang.toUpperCase()}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => handleChangeLang("eng")}>ENG</MenuItem>
            <MenuItem onClick={() => handleChangeLang("spa")}>SPA</MenuItem>
            <MenuItem onClick={() => handleChangeLang("por")}>POR</MenuItem>
          </Menu> */}
        </ListItemButton>
      </ListItem>

      <ListItem key="lang" disablePadding>
        <ListItemButton sx={{ textAlign: 'center' }}>
          <Button onClick={() => navigate("/login")} className="btn-login" >
            {/* Iniciar sesión */}
            {language[lang].login}
          </Button>
        </ListItemButton>
      </ListItem>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={mytheme}>
        <AppBar component="nav">
          <Toolbar style={{ "justify-content": "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 0, display: { sm: 'block' } }}
            >
              <ImageLogo className="nav-logo" />
            </Typography>
            <Box textAlign="center" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Link to={item.path} smooth={true} duration={500}>
                  <Button key={item} className="nav-btn" sx={{textTransform: 'none'}}>
                    {item.name}
                  </Button>
                </Link>
              ))}
            </Box>
            <Grid style={{ width: 'auto', "min-width": "fit-content" }} container justifyContent="space-between" alignItems="center" textAlign="center" spacing="2" sx={{ display: { xs: 'none', sm: 'block' } }}>
              <LanguagePopover />

              {/* <Button
                endIcon={<ExpandMoreOutlinedIcon />}
                className="nav-btn"
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}

              >
                {lang.toUpperCase()}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => handleChangeLang("eng")}>ENG</MenuItem>
                <MenuItem onClick={() => handleChangeLang("spa")}>SPA</MenuItem>
                <MenuItem onClick={() => handleChangeLang("por")}>POR</MenuItem>
              </Menu> */}

              <Button onClick={() => navigate("/login")} className="btn-login" >
                {/* Iniciar sesión */}
                {language[lang].login}
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>

      </Box>
    </Box>
  );
}

NavBarMain.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavBarMain;
